//import { useContext } from "react";
import history from "../history";
import ApiAuth from "../services/apis/ApiAuth";
import ApiComercial from "../services/apis/ApiComercial";
import { removeAll } from "./sessionStorage";
//import { Context } from '../context/AuthContext';



export default function Logout() {

    //const {setAuthFalse} = useContext(Context);

    localStorage.clear();
    //setAuthFalse();
    ApiAuth.defaults.headers.Authorization = undefined;
    ApiComercial.defaults.headers.Authorization = undefined;
    removeAll();

    history.push('/login');

}