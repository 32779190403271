
import { useContext } from 'react';
import { Context } from '../context/AuthContext';
import { RouterContext } from '../context/RouterContext';
import clicked from '../utils/redirect';
import { getStorage } from '../utils/sessionStorage';
import { strings } from '../utils/strings';

export default function Menu() {

    const { authenticated } = useContext(Context);
    const role = JSON.parse(getStorage(strings.role));
    const dropMenu = window.innerWidth <= 992 ? 'pushmenu' : '';
    
    const {activeRoute} = useContext(RouterContext);   
    
    function clickMenu(path:string){
        clicked(path);
    }

    if (authenticated) {
        return (
            <div>   
                    <aside className="main-sidebar elevation-4 sidebar-light-primary">
                        {/* Brand Logo */}
                        <a href="javascript:void(0)" onClick={() => clicked('/')} className="brand-link navbar">
                            <img src="/dist/img/hsvp-logo.png" alt="Hsvp Logo" />
                            <span className="brand-text font-weight-light" />
                        </a>
                        {/* Sidebar */}
                        <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition"><div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{ left: 0, right: 'auto' }} /></div><div className="os-size-auto-observer observed" style={{ height: 'calc(100% + 1px)', float: 'left' }}><div className="os-resize-observer" /></div><div className="os-content-glue" style={{ margin: '0px -8px', width: 249, height: 879 }} /><div className="os-padding"><div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}><div className="os-content" style={{ padding: '0px 8px', height: '100%', width: '100%' }}>
                            {/* Sidebar Menu */}
                            <nav className="mt-2">
                                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                    {/* Add icons to the links using the .nav-icon class
                            with font-awesome or any other icon font library */}
                                    <li className="nav-item">
                                        <a href="javascript:void(0)" data-widget={dropMenu}  onClick={() => clickMenu('/')} className={`nav-link ${ activeRoute === '/' || activeRoute === '/home' ? 'active' : ''}`}>
                                            <i className="nav-icon far fa-image" />
                                            <p>
                                                Dashboard
                                            </p>
                                        </a>
                                    </li>
                                    {(role === 'ADMIN' || role === 'MEDICO') && 
                                        <li className="nav-item has-treeview menu-open">
                                            <a href="javascript:void(0)" className={`nav-link ${ activeRoute === '/orcamentos' || activeRoute === '/pacotes' ? 'active' : ''}`} data-toggle="push-menu">
                                                <i className="nav-icon fa fa-briefcase" />
                                                <p>
                                                    Solicitação Proced.
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </a>
                                            <ul className="nav nav-treeview">
                                                <li className="nav-item">
                                                    <a href="javascript:void(0)" data-widget={dropMenu}  onClick={() => clickMenu('/orcamentos')} className={`nav-link ${ activeRoute === '/orcamentos' ? 'active' : ''}`}>
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Orçamentos</p>
                                                    </a>
                                                    <a href="javascript:void(0)" data-widget={dropMenu}  onClick={() => clickMenu('/pacotes')} className={`nav-link ${ activeRoute === '/pacotes' ? 'active' : ''}`}>
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Proced. Gerenciados</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {(role === 'ADMIN' || role === 'COMERCIAL') && 
                                    <li className="nav-item has-treeview menu-open">
                                        <a href="javascript:void(0)"  className={`nav-link ${ activeRoute === '/comercialOrcamentos'  || activeRoute === '/documentos' ? 'active' : ''}`}>
                                            <i className="nav-icon fa fa-briefcase" />
                                            <p>
                                                Comercial
                                                <i className="right fas fa-angle-left" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <a href="javascript:void(0)" data-widget={dropMenu}  onClick={() => clickMenu('/comercialOrcamentos')} className={`nav-link ${ activeRoute === '/comercialOrcamentos' ? 'active' : ''}`}>
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Orçamentos</p>
                                                </a>
                                                <a href="javascript:void(0)" data-widget={dropMenu} onClick={() => clickMenu('/documentos')} className={`nav-link ${ activeRoute === '/documentos' ? 'active' : ''}`}>
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Proced. Gerenciado</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    }
                                    {(role === 'ADMIN' || role === 'AGENDAMENTO') && 
                                    <li className="nav-item has-treeview menu-open">
                                        <a href="javascript:void(0)"  className={`nav-link ${ activeRoute === '/agendamentoOrcamentos' ? 'active' : ''}`}>
                                            <i className="nav-icon fa fa-briefcase" />
                                            <p>
                                                Agendamento
                                                <i className="right fas fa-angle-left" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <a href="javascript:void(0)" data-widget={dropMenu}  onClick={() => clickMenu('/agendamentoOrcamentos')} className={`nav-link ${ activeRoute === '/agendamentoOrcamentos' ? 'active' : ''}`}>
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Agendamentos</p>
                                                </a>                                               
                                            </li>
                                        </ul>
                                    </li>
                                    }
                                </ul>
                            </nav>
                            {/* /.sidebar-menu */}
                        </div></div></div><div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ width: '100%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ height: '69.9523%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar-corner" /></div>
                        {/* /.sidebar */}
                    </aside>
            </div>
        );
    } else {
        return (
            <></>
        );
    }

}
