
export default function PageTitle(props:Props) {

    return(
        <div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h3 className="m-0 text-dark">{props.title}</h3>
                    </div>{/* /.col */}

                    </div>
                </div>
            </div>
        </div>
    );
}

interface Props{
    title: string, 
    href: string, 
    breadcumbMain: 
    string, 
    breadcumbSecondary: string
}
