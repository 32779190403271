import { useState, useEffect } from 'react';

import apiAuth from '../../services/apis/ApiAuth';
import history from '../../history';
import apiComercial from '../../services/apis/ApiComercial';
import { getStorage,  setStorage } from '../../utils/sessionStorage';
import * as messages from '../../components/Toastr';
import { strings } from '../../utils/strings';
import logout from '../../utils/logout';


export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();


  useEffect(() => {
    
    //const token = JSON.parse(localStorage.getItem('token'));
    const token = JSON.parse( getStorage(strings.token) );

    if (token) {
      setAuthorization(token);
      setAuthenticated(true);
    }else{
      setAuthenticated(false);
    }

    setLoading(false);
  }, []);
  
  async function handleLogin() {

    if(!password || !username){
      messages.mensagemErro('Informe o usuário e senha.');
      return;
    }

    apiAuth.post('/signin', {}, {
      auth:{
        username, 
        password
      }
    }).then( response => {
      
      const token = response.data.token;
      const role  = response.data.role;
      const user  = response.data.user;
      setStorage(strings.role, JSON.stringify(role));
      setStorage(strings.user, JSON.stringify(user));
      setStorage(strings.token, JSON.stringify(token));
      setAuthorization(token);
      setAuthenticated(true);
      history.push('/home');

    }).catch(error => {
      messages.mensagemErro('Login ou senha inválido(s).');
      setErrorMessage('Login ou senha inválido(s).');
    });

  }
  function handleLogout() {
    setAuthenticated(false);
    logout();
  }

  function setUserInput(userInput){
    setUsername(userInput);
  }

  function setPasswordInput(pass){
    setPassword(pass);
  }

  function setAuthorization(token){
    apiAuth.defaults.headers.Authorization = `Bearer ${token}`;
    apiComercial.defaults.headers.Authorization = `Bearer ${token}`;
  }

  function setAuthFalse(){
    setAuthenticated(false);
  }
  
  return { authenticated, loading, handleLogin, handleLogout, setUserInput, setPasswordInput, errorMessage, setAuthFalse };
}
