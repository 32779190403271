import { createContext, ReactNode, useState } from "react";

interface LoaderContextData{
    setShow: (status:boolean) => void;    
    loading : boolean;
}

interface LoaderProviderProps{
    children: ReactNode;
}

export const LoaderContext = createContext( { } as LoaderContextData);

export function LoaderProvider( {children} : LoaderProviderProps){

    const [loading, setLoading] = useState(false);
   
    function setShow(status:boolean){
        setLoading(status)
    } 


    return (
        <LoaderContext.Provider value={ {loading, setShow} }>
            {children}
        </LoaderContext.Provider>
    )
}