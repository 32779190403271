import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import modules from "./lang/pt-BR.json";
import Login from './components/Login';
import NotFound from './pages/notfound';

import { Context } from './context/AuthContext';
import Dashboard from './pages/dashboard';
import Documentos from './pages/comercial/documentos';
import PageTitle from './components/PageTitle';
import DocumentoForm from './pages/comercial/documentoForm';
import Pacotes from './pages/solicProceds/pacotes';
import Orcamentos from './pages/solicProceds/orcamentos';
import OrcamentoForm from './pages/solicProceds/orcamentoForm';
import ComercialOrcamentos from './pages/solicProceds/comercialOrcamentos';
import AgendamentoOrcamentos from './pages/agendamento/agendamentoOrcamentos';
import { RouterContext } from './context/RouterContext';



function CustomRoute({ isPrivate , ...props }) {


  const { loading, authenticated } = useContext(Context);
  const {setRoute} = useContext(RouterContext);   


  if (loading) {
    return <h3>Carregando...</h3>;
  }

  if ( (isPrivate && !authenticated) ) {
    return <Redirect to="/login" />
  }

  if (props.path === "/login"){
    return (
      <Route {...props} />
    );
  }

 //@uzkha
 //para rotas de edicao /orcamentos/editar/id 
 //nao altera o active atual, pois a forms usados em mais de um modulo
 if((props.path.split('/').length) <= 2){
    const path = (props.path.split('/').length - 1) === 1 ? 
                    props.path.substring(props.path.indexOf('/', 1) + 1) : 
                      props.path.substring(
                        props.path.indexOf("/"), 
                        props.path.lastIndexOf("/")
                      );
    setRoute(path);
 }

  return (
    <>         
      <div className="wrapper">
        <div className="content-wrapper" style={{minHeight: 823}}> 
            <PageTitle title={modules[props.component.name]} /> 
            <section class="content">
              <div class="container-fluid">
                <div class="card">
                    <Route {...props} />
                  </div>
                </div>
            </section>
        </div>
      </div>
    </>    
  );
}

export default function Routes() {
  return (
    <Switch> 
      <CustomRoute false exact path="/login" component={Login} />
      <CustomRoute isPrivate exact path="/" component={Dashboard} />
      <CustomRoute isPrivate exact path="/home" component={Dashboard} />
      <CustomRoute isPrivate exact path="/agendamentoOrcamentos" component={AgendamentoOrcamentos} />
      <CustomRoute isPrivate exact path="/comercialOrcamentos" component={ComercialOrcamentos} />
      <CustomRoute isPrivate exact path="/orcamentos" component={Orcamentos} />
      <CustomRoute isPrivate exact path="/orcamentos/adicionar" component={OrcamentoForm} />
      <CustomRoute isPrivate exact path="/orcamentos/editar/:id?" component={OrcamentoForm} />
      <CustomRoute isPrivate exact path="/pacotes" component={Pacotes} />
      <CustomRoute isPrivate exact path="/documentos" component={Documentos} />
      <CustomRoute isPrivate exact path="/documentos/adicionar" component={DocumentoForm} />
      <CustomRoute isPrivate exact path="/documentos/editar/:id?" component={DocumentoForm} />      
      <CustomRoute isPrivate component={NotFound} />
    </Switch>
  );
}
