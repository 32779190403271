import { createContext, ReactNode, useState } from "react";

interface ModalContextData{
    setShowModal: () => void;
    setTitle : (title : string) => void;
    setMessageModal: (message : string) => void;
    setConfirmModal: (status: boolean) => void;
    setFinalizadoModal: (status : boolean) => void;
    finalizado : boolean;
    confirm:boolean;
    show: boolean;
    modalTitle : string;
    message : string;
}

interface ModalProviderProps{
    children: ReactNode;
}

export const ModalContext = createContext( { } as ModalContextData);

export function ModalProvider( {children} : ModalProviderProps){

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [finalizado, setFinalizado] = useState(false);


    function setShowModal(){
        setShow(!show);
    } 

    function setFinalizadoModal(status : boolean){
        setFinalizado(status);
    }

    function setTitle(title : string){
        setModalTitle(title);
    }

    function setMessageModal( messageParam : string){
        setMessage(messageParam);
    }

    function setConfirmModal( confirmParam : any ){
        setConfirm(confirmParam);
    }


    return (
        <ModalContext.Provider value={ {confirm, finalizado, setFinalizadoModal, message, show, setTitle, modalTitle,setShowModal, setMessageModal, setConfirmModal} }>
            {children}
        </ModalContext.Provider>
    )
}