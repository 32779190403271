
import {DocumentosInterface} from '../../interfaces/documentInterface';
import DataTable from 'react-data-table-component';
import {customStyles} from '../../utils/datatableTheme';
import { useEffect, useState } from 'react';

export default function DocumentosTable(props: Props)  {



  const [omitColumn, setOmitColumn] = useState(false);


  useEffect(() => {
    if(window.innerWidth < 769){
      setOmitColumn(true);
    }
  }, []); 
 
  


    const array =  Object.values(props.documentos);
    
    const rows = array.map( documento => {

    
        const vigencia = new Date(documento.vigencia ? documento.vigencia : '');
        vigencia.setHours(vigencia.getHours() + 3);
        const date = new Date();   
        date.setHours(0,0,0,0);
 

        const color = (!documento.status || vigencia < date )? 'red' : 'black';

        return( 
            {
                id: documento.id,
                descricao : <span style={{color}}>{documento.descricao}</span>,
                procInterno : <span style={{color}}>{`${documento.nr_seq_proc_interno} - ${documento.codigo} - ${documento.procedimento ? documento.procedimento.ds_procedimento : ''}`}</span>,
                especialidade : documento.especialidade ? <span style={{color}}>{documento.especialidade.ds_especialidade}</span> : '',
                status : <span style={{color}}>{documento.status ? 'Ativo' : 'Inativo'}</span>,
                viewDoc: 
                          <div>
                          <span style={{padding: "5px"}}><button onClick={ () => props.getFile(documento.descricao, documento.id, documento.arquivo)} ><i className="fa fa-file-pdf" title="visualizar doc" aria-hidden="true"></i></button></span>
                          <span style={{padding: "5px"}}><button onClick={ () => props.editar(documento.id)} ><i className="fa fa-edit" title="editar" aria-hidden="true"></i></button></span>
                          <span style={{padding: "5px"}}><button onClick={ () => props.inativar(documento.id, documento.status, documento.descricao)} ><i className={documento.status ? "fa fa-ban" : "fa fa-check-circle"} title={documento.status ? "inativar" : "ativar"} aria-hidden="true"></i></button></span>
                          </div>

            }
        )
    });

    const columns = [
      {
        name: 'Descrição',
        selector: 'descricao',
        sortable: true,
        responsive: true,     
        width: "20%" 
      },
      {
        name: 'Procedimento',
        selector: 'procInterno',
        sortable: true,
        responsive: true, 
        width: "40%"
      },    
      {
        name: 'Especialidade',
        selector: 'especialidade',
        sortable: true,
        right: true,
        responsive: true,
        omit: omitColumn  
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        right: true,
        responsive: true   ,
        omit: omitColumn             
      },
      {
        name: '',
        selector: 'viewDoc',
        sortable: false,
        right: true,
        responsive: true,
        minWidth: '150px'        
      },


    ];

    return (
      <div className="form-horizontal">
        <div className="card-body">
          <DataTable
              customStyles={customStyles}
              data={rows}
              columns={columns}
              pagination={true}
              className="table table-bordered"
              responsive={true}
              paginationComponentOptions= { {rowsPerPageText : 'Resultados por página'}}
          />
        </div>
      </div>

    );

}


interface Props{
    documentos: DocumentosInterface;
    getFile: (name: string, id:string, fileName:string) => void;
    editar: (id:string) => void;
    inativar: (id:string, status:boolean, name:string) => void;
}