
function CheckButton(props : any){
    
    return(      
        <div className="form-check form-check-inline" >
            <input type="checkbox" className="form-check-input" checked={ props.checked } onChange={props.onClick} id={props.value} />
            <label className="form-check-label" onClick={props.onClick} >{props.label}</label>
        </div>      
    );
}

export default CheckButton;


