import { useState, useEffect, useContext } from "react";
import FormGroup from "../../components/FormGroup";
import history from "../../history";
import { Filter } from "../../interfaces/documentInterface";
import ComercialService from "../../services/comercial/comercialService";
import DocumentosTable from "./documentosTable";
import * as session from '../../utils/sessionStorage';
import { letters, numbers, strings } from '../../utils/strings'
import * as messages from '../../components/Toastr';
import { ModalContext } from "../../context/ModalContext";
import { LoaderContext } from "../../context/LoaderContext";
import Select from 'react-select';
import { getEspecialidades, getMedicos, getProcedimentos } from "../../utils/getSelects";
import comercialGetFile from "../../utils/comercialGetFile";

function Documentos() {

    const [descricao, setDescricao] = useState<String | undefined | any>(getSearch("desc"));
    const [cd_especialidade, setCdEspecialidade] = useState<String | undefined | any>(getSearch("esp"));
    const [codigo, setCodigo] = useState<Number | undefined | any>(getSearch("cod"));
    const [procedimento, setProcedimento] = useState<String | undefined | any>(getSearch("proc"));
    const [rows, setRows] = useState([]);
    const [idCan, setIdcan] = useState<string>();
    const [statusCan, setStatusCan] = useState<boolean>();
    const [procedimentos, setProcedimentos] = useState([]);
    const [searching, setSearching] = useState(false);
    const [descProced, setDescProced] = useState('');
    const [origemProced, setOrigemProced] = useState();
    const [medicos, setMedicos] = useState([]);
    const [usuario, setUsuario] = useState<String | undefined | any>(getSearch("usuario"));
    const [especialidades, setEspecialidades] = useState([])
    const [nr_seq_proc_interno, setProcInterno] = useState<String | undefined | any>(getSearch("procInterno"))
    const { setShowModal, setTitle, setConfirmModal, setMessageModal, setFinalizadoModal, finalizado, confirm } = useContext(ModalContext);

    const comercialService = new ComercialService();

    const { setShow } = useContext(LoaderContext);

    useEffect(() => {
        getProceds();
    }, []);

    useEffect(() => {
        getUpdate();
        getSelects();
    }, [finalizado, confirm])

    function getUpdate() {

        if (confirm) {
            inativarItem();
        }
   }


    async function getSelects() {
        setShow(true);

        if (!medicos || medicos.length < 1) {
            const listMedicos = await getMedicos();
            setMedicos(listMedicos);
        }

        if (!especialidades || especialidades.length < 1) {
            const listaEspecialidades = await getEspecialidades();
            setEspecialidades(listaEspecialidades);
        }

        setShow(false);
    }

    function getSearch(type: string) {
        const search = JSON.parse(session.getSession(strings.documentos));
        let field;

        if (search) {
            switch (type) {
                case "desc":
                    field = search.descricao;
                    break;
                case "esp":
                    field = search.cd_especialidade;
                    break;
                case "cod":
                    field = search.codigo;
                    break;
                case "proc":
                    field = search.procedimento;
                    break;
                case "usuario":
                    field = search.usuario;
                    break;
                case "procInterno":
                        field = search.nr_seq_proc_interno;
                        break;
                default:
                    break;
            }

        }
        return field;
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setSearchSession();
        getProceds();
    }

    function setSearchSession() {
        const search = {
            descricao,
            cd_especialidade,
            codigo,
            procedimento,
            usuario,
            nr_seq_proc_interno
        }

        session.setSession(strings.documentos, JSON.stringify(search));
    }

    function getProceds() {


        setShow(true);

        const filter: Filter = {
            descricao,
            cd_especialidade: cd_especialidade ? cd_especialidade.value : undefined,
            codigo,
            origemProced,
            usuario: usuario ? usuario.value : undefined,
            nr_seq_proc_interno
        }

        comercialService.get(filter)
            .then(response => {
                setRows(response.data.procedGerenciados);
            })
            .catch(error => {
                console.log(error.response);
            })
            .finally(() =>
                setShow(false)
            );

    }

    function adicionar() {
        history.push("/documentos/adicionar");
    }


    async function getFile(name: string, id: string, fileName: string) {

        setShow(true);
        await comercialGetFile(name, id, fileName, 'pacote');
        setShow(false);
        
    }

    function editar(id: string) {
        history.push(`/documentos/editar/${id}`);
    }

    function inativarItem() {

        setShow(true);

        const documento = {
            status: !statusCan
        }

        comercialService.patch(idCan, documento)
            .then(response => {
                messages.mensagemSucesso("Status alterado com sucesso");
                getProceds();

            })
            .catch(error => {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao tentar alterar o status do registro.")
            })
            .finally(() =>
                setShow(false)
            );

        setFinalizadoModal(false);
        setConfirmModal(false);
    }


    function inativar(id: string, status: boolean, name: string) {

        setIdcan(id);
        setStatusCan(status);

        setTitle("Confirmação");
        setMessageModal(`Confirma a ${status ? ' inativação ' : ' ativação '} do registro: ${name}?`);
        setShowModal();

    }

    async function selectProcedClicked(e) {

        if (letters.includes(e.keyCode) || numbers.includes(e.keyCode)) { // enter

            if (descProced.length > 2 && !searching) {
                try {
                    setSearching(true);
                    const search = descProced.replaceAll(" ", "+");
                    const rows = await getProcedimentos(search, [4]);
                    setProcedimentos(rows);
                    setSearching(false);
                } catch (error) {
                    console.log(error);
                }
                setDescProced('');
            }

        }
    }

    function setProcedimentoSelected(e) {

        setProcedimento(e);
        if (e !== null) {
            setCodigo(e.value);
            const pos = e.label.search("-");
            const origem = e.label.substr(pos + 2, 1);
            if (origem) {
                setOrigemProced(origem);
            }
        }
        setDescProced('');
    }

    return (
        <div>
            <div className="form-horizontal">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputDescricao" label="Descrição" htmlFor="descricao">
                                <input type="text" className="form-control" id="descricao" placeholder="digite a descrição"
                                    name="descricao" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputEspecialidade" label="Especialidade" htmlFor="especialidade">
                                <Select isClearable={true} placeholder="Seleciona a especialidade..." name="cd_especialidade" options={especialidades} value={cd_especialidade} onChange={(e) => setCdEspecialidade(e)} />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                id="inputProcedimento" label="Procedimento" htmlFor="procedimento">
                                <Select placeholder="Digite o procedimento e tecle Enter para pesquisar..." name="procedimento"
                                    options={procedimentos} value={procedimento}
                                    onInputChange={(e) => setDescProced(e)}
                                    onKeyDown={(e) => selectProcedClicked(e)}
                                    onChange={(e) => setProcedimentoSelected(e)}
                                    isClearable={true}
                                />
                            </FormGroup>                           
                        </div>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputProcInterno" label="Código Proc Interno" htmlFor="procInterno">
                                <input type="text" className="form-control" id="nr_seq_proc_interno" placeholder="digite o proc interno"
                                    name="nr_seq_proc_interno" value={nr_seq_proc_interno} onChange={(e) => setProcInterno(e.target.value)} />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputCodProced" label="Código Procedimento" htmlFor="codigo">
                                <input type="text" className="form-control" id="codigo" placeholder="digite o código"
                                    name="codigo" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                id="inputUsuarios" label="Médico" htmlFor="usuarios">
                                <Select placeholder="Selecione o medico" isClearable={true} name="medico" options={medicos} value={usuario} onChange={(e) => setUsuario(e)} />
                            </FormGroup>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary float-right">Pesquisar</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card">
                <div className="card-header">
                    <h3 className="card-title"> </h3>
                    <button className="btn btn-success float-right" onClick={adicionar}>Adicionar</button>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <DocumentosTable documentos={rows} getFile={getFile} editar={editar} inativar={inativar} />
                </div>
            </div>
        </div>

    );

}

export default Documentos;
