import { useEffect, useState } from "react";
import { OrcamentosInterface } from "../../interfaces/orcamentoInterface";
import { customStyles } from "../../utils/datatableTheme";
import DataTable from 'react-data-table-component';
import { format } from "date-fns";

export default function ComercialOrcamentosTables(props: Props){


    const [omitColumn, setOmitColumn] = useState(false);

    useEffect(() => {
        if(window.innerWidth < 769){
          setOmitColumn(true);
        }
      }, []); 
     
    const array =  Object.values(props.orcamentos);
    
    const rows = array.map( orcamento => {

        let formattedDate;
        if(orcamento.dt_agenda){
          const date = new Date(orcamento.dt_agenda);
          formattedDate = format(date, "dd/MM/yyyy HH:mm");
        }
        const editar = orcamento.orcamentoStatus.alterar;
        const cancelar = orcamento.orcamentoStatus.cancelar ? false : true;

        let status;
        if(orcamento.status_id === 'C'){
          if(orcamento.proced_id){
            status = 'PA';
          }else{
            status = 'AO'
          }
        }else{
          status = 'C';
        }
        return( 
            {
              id: orcamento.id,
              nmPaciente : orcamento.nm_paciente,
              dtAgenda:  formattedDate,
              dsProcedimento : orcamento.ds_procedimento,                
              status : orcamento.orcamentoStatus ? orcamento.orcamentoStatus.nome : '',
              nr_seq_proc_interno : <div>{orcamento.nr_seq_proc_interno ? orcamento.nr_seq_proc_interno : ''}{'  '}{orcamento.proced_id ? <i style={{color:'blue'}} className='fa fa-bookmark' title='Proced. Gerenciado' ></i> : ''}</div>,
              viewDoc: 
                  <div>
                    { orcamento.status_id === 'AO' || orcamento.status_id === 'R' ?  <span style={{padding: "5px"}}><button onClick={ () => props.enviarOrcamento(orcamento.id, orcamento.nm_paciente, orcamento.ds_procedimento, orcamento.observacao, orcamento.nr_seq_proc_interno, orcamento.observacaoComercial)}><i className="fa fa-paper-plane" title='Enviar Orçamento' aria-hidden="true"></i></button></span> : null}
                    { orcamento.status_id === 'OE' ?  <span style={{padding: "5px"}}><button onClick={ () => props.inativar(orcamento.id, 'AO', `Deseja estornar o orçamento - ${orcamento.nm_paciente} - ${orcamento.ds_procedimento} ?`, true)}><i className="fa fa-arrow-alt-circle-left" title='Estornar orçamento' aria-hidden="true"></i></button></span>: null}
                    <span style={{padding: "5px"}}><button onClick={ () => props.visualizar(orcamento.id)}><i className={editar ? "fa fa-edit" : "fa fa-search"} title={editar ? 'editar' : 'visualizar'} aria-hidden="true"></i></button></span>
                    <span style={{padding: "5px"}}><button disabled={!orcamento.arquivo} onClick={ () => props.getFile(orcamento.descricao, orcamento.id, orcamento.arquivo)} ><i className="fa fa-file-pdf" title="visualizar orçamento" aria-hidden="true"></i></button></span>
                    <span style={{padding: "5px"}}><button disabled={cancelar} onClick={ () => props.inativar(orcamento.id, status, `${status !== 'C' ? 'Deseja voltar para pendente - ' : 'Deseja cancelar - ' } ${orcamento.nm_paciente} - ${orcamento.ds_procedimento} ?`, false)} ><i className={orcamento.status_id !== 'C' ? "fa fa-times-circle" : "fa fa-recycle"} title={orcamento.status_id !== 'C' ? "cancelar" : "voltar para pendente"} aria-hidden="true"></i></button></span>
                  </div> 

            }
        )
    });


    const columns = [
        {
          name: 'Paciente',
          selector: 'nmPaciente',
          sortable: true,
          responsive: true,     
          style:{ fontWeight: 1000}
        },
        {
          name: 'Proc. Interno',
          selector: 'nr_seq_proc_interno',
          sortable: true,
          responsive: true, 
          maxWidth: "130px"
        },    
        {
          name: 'Procedimento',
          selector: 'dsProcedimento',
          sortable: true,
          responsive: true, 
        },    
      
        {
          name: 'Data Prevista',
          selector: 'dtAgenda',
          sortable: false,
          right: true,
          responsive: true,
          omit: omitColumn,
          maxWidth: "130px"
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          right: true,
          responsive: true,
          style:{ fontWeight: 500}
      
        },
        {
            name: '',
            selector: 'viewDoc',
            sortable: false,
            right: true,
            responsive: true,
            minWidth: '200px'         
          },
  
  
      ];


    return (
      
        <div className="form-horizontal">
            <div className="card-body">
            <DataTable
                customStyles={customStyles}
                data={rows}
                columns={columns}
                pagination={true}
                className="table table-bordered"
                responsive={true}
                paginationComponentOptions= { {rowsPerPageText : 'Resultados por página'}}
            />
            </div>
        </div>
    )
}


interface Props{
    orcamentos: OrcamentosInterface;
    getFile: (name: string, id:string, fileName:string) => void;
    editar: (id:string) => void;
    inativar: (id:string, status:string, mensagem:string, removerArquivo:boolean) => void;
    visualizar: (id:string) => void;
    enviarOrcamento: (id:string, nm_paciente:string, ds_procedimento: string, observacao: string, procInterno: string, observacaoComercial: string) => void;
}