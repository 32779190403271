

export const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        background: "#f2f2f2",
        font: "bold",
        fontSize: "16px",
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: "#e6e6e6"
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        paddingLeft: '5px', // override the cell padding for data cells
        paddingRight: '5px',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: "#e6e6e6"
      },
    },
};
  
