import { useContext, useEffect, useState } from "react";
import FormGroup from "../../components/FormGroup";
import { LoaderContext } from "../../context/LoaderContext";
import ComercialService from "../../services/comercial/comercialService";
import Select from 'react-select';
import { Estabelecimentos } from "../../utils/estabelecimentos";
import comercialGetFile from "../../utils/comercialGetFile";
import { getOrcamentoStatus } from "../../utils/getSelects";
import { FilterOrcamento } from "../../interfaces/orcamentoInterface";
import * as session from '../../utils/sessionStorage';
import { strings } from "../../utils/strings";
import history from "../../history";
import { ModalContext } from "../../context/ModalContext";
import * as messages from '../../components/Toastr';
import { Button, Modal } from "react-bootstrap";
import ComercialOrcamentosTables from "./comecialOrcamentosTable";
import CheckButton from "../../components/CheckButton";




function ComercialOrcamentos(){

    const comercialService = new ComercialService();

    const { setShow } = useContext(LoaderContext);

    const [rows, setRows] = useState([]);
    const [nomePacienteSearch, setNomePacienteSearch] = useState<string>(getSearch("paciente"));
    const [estabelecimentoSearch, setEstabelecimentoSearch] = useState<any>(getSearch("estab"));
    const [dtAgendaSearch, setDtAgendaSearch] = useState<string>(getSearch("agenda"));
    const [statusSearch, setStatusSearch] = useState<Array<string>>(getSearch("status"));
    const [statusList, setStatusList] = useState([]);
    const [statusListCheck, setStatusListCheck] = useState([]);
    const [idCan, setIdcan] = useState<string>();
    const [statusCan, setStatusCan] = useState<string>();
    const [showModalAgenda, setShowModalAgenda] = useState(false);
    const [estabelecimento, setEstabelecimento] = useState<any>();
    const [dtAgenda, setDtAgenda] = useState<string>('');
    const [hrAgenda, setHrAgenda] = useState<string>('');
    const [observacao, setObservacao] = useState<string>();
    const [observacaoComercial, setObservacaoComercial] = useState<string>();
    const [valEstab, setValEstab] = useState<string>();
    const [valDtAgenda, setValDtAgenda] = useState<string>();
    const [valHrAgenda, setValHrAgenda] = useState<string>();
    const [zIndex, setZIndex] = useState(10000);
    const [orcamentoId, setOrcamentoId] = useState<string>();
    const [modalTitle, setModalTitle] = useState<string>();
    const [nm_paciente, setNmPaciente] = useState<string>();
    const [ds_procedimento, setDsProcedimento] = useState<string>();
    const [uploadFile, setUploadFile] = useState<File>();
    const [fileName, setFileName] = useState<string>();
    const [removerArquivo, setRemoverArquivo] = useState<boolean>();
    const [nr_seq_proc_interno, setNrSeqProcInterno] = useState<string>();
    const [nrSeqProcInternoSearch, setNrSeqProcInternoSearch] = useState<string>(getSearch("procInterno"));
    const [request, setRequest] = useState<boolean>();
    const [time, setTime] = useState(0);

    let countdownTimeout: NodeJS.Timeout;

    const { setShowModal, setTitle, setConfirmModal, setMessageModal, setFinalizadoModal, finalizado, confirm } = useContext(ModalContext);

    const estabelecimentos = Estabelecimentos;

    useEffect( () => {
        if(!request){
            countdownTimeout = setTimeout( () => {
                setTime(time + 1);
            }, 300000);
        }
    }, [time]);

    useEffect(() => {
        setSearchSession();
        getProceds();        
    }, [time]);

    useEffect(() => {
        getProceds();
    }, []);

    useEffect(() => {
        getUpdate();        
    }, [finalizado, confirm]);

    useEffect(() => {
        setStatusCheck();        
    }, [statusList, statusSearch]);

    function getUpdate() {

        if (confirm) {
            inativarItem();
        }
   }


   function setStatusClicked(statusId){        
        if(statusSearch.find(element => element === statusId)){
            const array = statusSearch;
            const index = array.indexOf(statusId);
            if (index > -1) {
                array.splice(index, 1);
            }
            setStatusSearch(array);   
            setStatusCheck();

        }else{
            setStatusSearch([...statusSearch, statusId]);            
        }
    }

    function setStatusCheck(){  
        const rows = statusList.map(function(key) {    
            return <CheckButton disabled={false} label={key.label} name={key.label} checked={ statusSearch.find(element => element === key.value)} onClick={ () => setStatusClicked(key.value)}  />
        });
        setStatusListCheck(rows);
    }

   
    function handleClose(){
        setShowModalAgenda(false);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

        setShow(true);   
        setZIndex(100);

        e.preventDefault();

        var formData = new FormData();

        if (uploadFile) {
            formData.append('arquivo', uploadFile);
        }

        //ormData.append('observacao', observacao);   
        formData.append('observacaoComercial', observacaoComercial);   
        formData.append('status_id', 'OE');
        formData.append('nr_seq_proc_interno', nr_seq_proc_interno);

        comercialService.patchConfirmarOrcamento(orcamentoId, formData)
        .then(response => {           
            handleClose();
            getProceds();
            messages.mensagemSucesso("Orçamento confirmado com sucesso.");
        }).catch(error => {
            messages.mensagemErro("Ocorreu um erro ao confirmar o registro.");
            console.log(error.response);
        }).finally(() =>
           finallyModal()
         );
    }
    function finallyModal(){
        setShow(false);
        setZIndex(10000);
    }

    async function getProceds() {

        if(!request){

            setShow(true);
            setRequest(true);

            if (!statusList || statusList.length < 1) {
                const statusList = await getOrcamentoStatus();
                setStatusList(statusList);
            }

            const filter: FilterOrcamento = {
                cd_estabelecimento: estabelecimentoSearch ? estabelecimentoSearch.value : undefined,
                nm_paciente: nomePacienteSearch ? nomePacienteSearch.replaceAll(" ", "+") : undefined,
                dt_agenda: dtAgendaSearch,
                status: statusSearch,
                nr_seq_proc_interno: nrSeqProcInternoSearch
                
            }

        
            comercialService.getOrcamentos(filter)
                .then(response => {
                    setRows(response.data.orcamentos);
                })
                .catch(error => {
                    console.log(error.response);
                })
                .finally(() =>
                    finalRequest()
                );

        }
    }
    
    function finalRequest(){
        setShow(false)
        setRequest(false)
    }

    function setSearchSession() {
        const search = {
            estabelecimentoSearch,
            nomePacienteSearch,
            dtAgendaSearch,
            statusSearch,
            nr_seq_proc_interno: nrSeqProcInternoSearch
        }

        session.setSession(strings.orcamentos, JSON.stringify(search));
    }

    function getSearch(type: string) {
        const search = JSON.parse(session.getSession(strings.orcamentos));
        let field;

        if (search) {
            switch (type) {
                case "estab":
                    field = search.estabelecimentoSearch;
                    break;
                case "paciente":
                    field = search.nomePacienteSearch;
                    break;
                case "agenda":
                    field = search.dtAgendaSearch;
                    break;
                case "status":
                    field = search.statusSearch ? search.statusSearch : setFirstStatus();
                    break;     
                case "procInterno":
                    field = search.nr_seq_proc_interno;
                    break;                       
                default:
                    break;
            }
        } else{
            if(type === 'status'){
                field = setFirstStatus(); 
            }
        }
        return field;
    }

    function setFirstStatus(){
        return ['AO', 'R'];
    }

    function handleSubmitSearch(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        setSearchSession();        
        getProceds();
    }

    async function getFile(name: string, id: string, fileName: string) {
        setShow(true);
        await comercialGetFile(name, id, fileName, 'orcamento');
        setShow(false);        
    }

    function editar(id:string){
        const viewBack = '/comercialOrcamentos';
        session.setSession(strings.viewBack, JSON.stringify(viewBack));
        history.push(`/orcamentos/editar/${id}`);
    }



    function inativarItem() {

        setShow(true);

        const documento = {
            status_id: statusCan,
            removerArquivo
        }

        comercialService.patchOrcamento(idCan, documento)
            .then(response => {
                messages.mensagemSucesso("Status alterado com sucesso");
                getProceds();

            })
            .catch(error => {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao tentar alterar o status do registro.")
            })
            .finally(() =>
                finalizarStatusItem()
            );


    }

    function finalizarStatusItem(){
        setConfirmModal(false);
        setFinalizadoModal(false);
        setShow(false)
        getProceds();
    }


    function inativar(id: string, status: string, messagem: string, removerArquivo: boolean) {

        setIdcan(id);
        setStatusCan(status);
        setRemoverArquivo(removerArquivo);

        setTitle("Confirmação");
        setMessageModal(messagem);
        setShowModal();

    }

    function visualizar(id:string){
        const viewBack = '/comercialOrcamentos';
        session.setSession(strings.viewBack,  JSON.stringify(viewBack));
        history.push(`/orcamentos/editar/${id}`);
    }

    function enviarOrcamento(id:string, nm_paciente:string, ds_procedimento: string, dsObservacao: string, procInterno: string, dsObservacaoComecial: string){

        setUploadFile(undefined);
        setFileName(undefined);
        setModalTitle('Enviar orçamento');
        setOrcamentoId(id);
        setNmPaciente(nm_paciente);
        setDsProcedimento(ds_procedimento);
        setNrSeqProcInterno(procInterno);
        setObservacao(dsObservacao);
        setShowModalAgenda(true);
        setObservacaoComercial(dsObservacaoComecial);


    }

    const handleUploadClick = event => {
        var fileAux = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(fileAux)

        setUploadFile(fileAux);
        setFileName(fileAux.name);
    };


    return (
        <div>
            <div className="form-horizontal">
                <div className="card-body">
                    <form onSubmit={handleSubmitSearch}>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputDescricao" label="Nome do Paciente" htmlFor="descricao">
                                <input type="text" className="form-control" id="descricao" placeholder="digite o nome do paciente"
                                    name="descricao" value={nomePacienteSearch} onChange={(e) => setNomePacienteSearch(e.target.value)} />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                 id="inputEstab" label="Selecione o Hospital" htmlFor="estabelecimento">
                                 <Select isClearable={true}
                                 placeholder="Seleciona a unidade..." name="cd_estabelecimento" 
                                 options={estabelecimentos} value={estabelecimentoSearch} onChange={(e) => setEstabelecimentoSearch(e)} />
                            </FormGroup>
                        </div>       
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                 id="inputDtAgenda" label="Data de Agendamento" htmlFor="inputDtAgenda">
                                 <input type="date" className="form-control" id="dtAgenda"
                                     name="dtAgenda" value={dtAgendaSearch} onChange={(e) => setDtAgendaSearch(e.target.value)}  />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputProcInterno" label="Código Proc Interno" htmlFor="procInterno">
                                <input type="text" className="form-control" id="nr_seq_proc_interno_search" placeholder="digite o proc interno"
                                    name="nr_seq_proc_interno_search" value={nrSeqProcInternoSearch} onChange={(e) => setNrSeqProcInternoSearch(e.target.value)} />
                            </FormGroup>
                        </div>     
                        <div className="row">
                            <div className="col-sm-12">                                
                                    <label>Status</label>
                                    <div className="form-group clearfix" >                                                                   
                                        {statusListCheck}
                                    </div>
                            </div>                           
                        </div>                    
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary float-right">Pesquisar</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card">
               
                {/* /.card-header */}
                <div className="card-body">
                    <ComercialOrcamentosTables orcamentos={rows} getFile={getFile} editar={editar} inativar={inativar} visualizar={visualizar} enviarOrcamento={enviarOrcamento} />
                </div>
            </div>

            <Modal  size="lg" style={{zIndex}} aria-labelledby="contained-modal-title-vcenter" centered show={showModalAgenda} onHide={ () => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } }>
            <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>           
            <Modal.Body>               
                <div className="card">                  
                    <div className="card-header" style={{background:"#e6e6ff"}}>
                        <strong>{modalTitle}</strong>
                    </div>
                    <hr />    
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputPaciente" label="Paciente" htmlFor="paciente">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="paciente"
                                name="nm_paciente" value={nm_paciente}  />                                
                        </FormGroup>
                    </div>      
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputProcedimento" label="Procedimento" htmlFor="procedimento">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="ds_procedimento"
                                name="ds_procedimento" value={ds_procedimento}  />                                
                        </FormGroup>
                    </div>       
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacao" label="Observação Médico" htmlFor="observacao">
                        <textarea autoComplete="off" disabled className="form-control" id="observacao" value={observacao}
                        onChange={(e) => setObservacao(e.target.value)} rows={3}></textarea>
                        </FormGroup>
                    </div>                      
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputProcInterno" label="Proc. Interno" htmlFor="nr_seq_proc_interno">
                             <input type="number" autoComplete="off"  className="form-control" id="nr_seq_proc_interno"
                                name="nr_seq_proc_interno" required value={nr_seq_proc_interno} onChange={(e) => setNrSeqProcInterno(e.target.value)}  />                                
                        </FormGroup>
                    </div>    
                    <div className="row"> 
                    <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputArquivo" label="Orçamento*" htmlFor="arquivo">
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" id="inputArquivoFile" required onChange={handleUploadClick} />
                                <label className="custom-file-label" htmlFor="inputArquivoFile">{fileName ? fileName : 'Selecione o arquivo PDF'}</label>
                            </div>
                            <div className="input-group-append">
                            </div>
                        </div>
                    </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacaoComercial" label="Observação Comercial" htmlFor="observacaoComercial">
                        <textarea autoComplete="off" className="form-control" id="observacaoComercial" value={observacaoComercial}
                        onChange={(e) => setObservacaoComercial(e.target.value)} rows={3}></textarea>
                        </FormGroup>
                    </div>      
                    
                </div>      

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary"  onClick={() => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } } >
                Fechar
            </Button>
            <Button type="submit" variant="primary">
                Confirmar
            </Button>           
            </Modal.Footer>
            </form>
            </Modal>

        </div>

    );


}
export default ComercialOrcamentos;