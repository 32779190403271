import FormGroup from "../../components/FormGroup";
import { useContext, useEffect, useState } from "react";
import { cpfMask } from "../../utils/cpfMask";
import RadioButton from "../../components/RadioButton";
import { acomodacoes, anestesias, diarias, diariasUti, quantidadesSangue, simNao, strings, temposSala, temposSalaMin } from "../../utils/strings";
import { LoaderContext } from "../../context/LoaderContext";
import * as messages from '../../components/Toastr';
import { getStorage } from "../../utils/sessionStorage";
import history from "../../history";
import ComercialService from "../../services/comercial/comercialService";
import * as session from '../../utils/sessionStorage';



function OrcamentoForm({ ...props }) {

    const params = props.match.params;
    const { setShow } = useContext(LoaderContext);
    const comercialService = new ComercialService();

    const role = JSON.parse(getStorage(strings.role));

    const [nm_paciente, setNmPaciente] = useState<string>();
    const [nr_cpf, setNrCpf] = useState<string>();
    const [dt_nascimento, setDtNascimento] = useState<string>();
    const [telefone, setTelefone] = useState<string>();
    const [ds_procedimento, setDsProcedimento] = useState<string>();
    const [cd_procedimento, setCdProcedimento] = useState<string>();
    const [anestesia, setAnestesia] = useState<string>();
    const [listaAnestesias, setListaAnestesias] = useState([]);
    const [acomodacao, setAcomodacao] = useState<string>();
    const [listaAcomodacoes, setListaAcomodacoes] = useState([]);
    const [diaria, setDiaria] = useState<string>();
    const [listaDiarias, setListaDiarias] = useState([]);
    const [diariaUti, setDiariaUti] = useState<string>();
    const [listaDiariasUti, setListaDiariasUti] = useState([]);
    const [qtSangue, setQtSangue] = useState<string>();
    const [listaQtSangue, setListaQtSangue] = useState([]);
    const [reservaSangue, setReservaSangue] = useState<string>();
    const [listaReservaSangue, setListaReservaSangue] = useState([]);
    const [perneira, setPerneira] = useState<string>();
    const [usoOpme, setUsoOpme] = useState<string>();
    const [listaUsoOpme, setListaUsoOpme] = useState([]);
    const [observacoes, setObservacoes] = useState<string>();
    const [tempoSala, setTempoSala] = useState<string>();
    const [listaTempoSala, setListaTempoSala] = useState([]);
    const [tempoSalaMin, setTempoSalaMin] = useState<string>();
    const [listaTempoSalaMin, setListaTempoSalaMin] = useState([]);
    const [formOpme, setFormOpme] = useState([{ opme: "", quantidade: 0, fornecedor : ""}])
    const [disabled, setDisabled] = useState(false);
    const [nr_seq_proc_interno, setNrSeqProcInterno] = useState<string>();

    useEffect(() => {
        getRegistro();
    }, []);

    useEffect(() => {
        mountAllRadios();
    }, [disabled]);
    
    useEffect(() => {
        setRadioAnestesias();
    }, [anestesia]);

    useEffect(() => {
        setRadioAcomodacoes();
    }, [acomodacao]);

    useEffect(() => {
        setRadioDiarias();
    }, [diaria]);
    useEffect(() => {
        setRadioDiariasUti();
    }, [diariaUti]);
    useEffect(() => {
        setRadioQtSangue();
    }, [qtSangue]);
    useEffect(() => {
        setRadioReservaSangue();
    }, [reservaSangue]);
    useEffect(() => {
        setRadioUsoOpme();
    }, [usoOpme]);
    useEffect(() => {
        setRadioTempoSala();
    }, [tempoSala]);
    useEffect(() => {
        setRadioTempoSalaMin();
    }, [tempoSalaMin]);

    function mountAllRadios(){
        setRadioAnestesias();
        setRadioAcomodacoes();
        setRadioDiarias();
        setRadioDiariasUti();
        setRadioQtSangue();
        setRadioReservaSangue();
        setRadioUsoOpme();
        setRadioTempoSala();
        setRadioTempoSalaMin();
    }

    function setRadioAnestesias(){        
        const rows = Object.keys(anestesias).map(function(key) {    
            return <RadioButton disabled={disabled} label={anestesias[key]} name="anestesias" checked={ anestesia === key } onClick={ () => setAnestesia(key)}  />
        });
        setListaAnestesias(rows);
    }

    function setRadioAcomodacoes(){        
        const rows = Object.keys(acomodacoes).map(function(key) {    
            return <RadioButton disabled={disabled} label={acomodacoes[key]} name="acomodacoes" checked={ acomodacao === key } onClick={ () => setAcomodacao(key)}  />
        });
        setListaAcomodacoes(rows);
    }

    function setRadioDiarias(){        
        const rows = Object.keys(diarias).map(function(key) {    
            return <RadioButton disabled={disabled} label={diarias[key]} name="diarias" checked={ diaria === key } onClick={ () => setDiaria(key)}  />
        });
        setListaDiarias(rows);
    }

    function setRadioDiariasUti(){        
        const rows = Object.keys(diariasUti).map(function(key) {    
            return <RadioButton disabled={disabled} label={diariasUti[key]} name="diariasUti" checked={ diariaUti === key } onClick={ () => setDiariaUti(key)}  />
        });
        setListaDiariasUti(rows);
    }

    function setRadioQtSangue(){        
        const rows = Object.keys(quantidadesSangue).map(function(key) {    
            return <RadioButton  disabled={disabled} label={quantidadesSangue[key]} name="qtSangue" checked={ qtSangue === key } onClick={ () => setQtSangue(key)}  />
        });
        setListaQtSangue(rows);
    }

    function setRadioReservaSangue(){  
        
        const rows = Object.keys(simNao).map(function(key) {   
            return <RadioButton disabled={disabled} label={simNao[key]} name="reservaSangue" checked={ reservaSangue === key } onClick={ () => setReservaSangue(key)}  />
        });
        setListaReservaSangue(rows);
    }

    function setRadioUsoOpme(){  
        
        const rows = Object.keys(simNao).map(function(key) {   
            return <RadioButton disabled={disabled} label={simNao[key]} name="usoOpme" checked={ usoOpme === key } onClick={ () => setUsoOpme(key)}  />
        });
        setListaUsoOpme(rows);
    }
    function setRadioTempoSala(){        
        const rows = Object.keys(temposSala).map(function(key) {    
            return <RadioButton disabled={disabled} label={temposSala[key]} name="tempoSala" checked={ tempoSala === key } onClick={ () => setTempoSala(key)}  />
        });
        setListaTempoSala(rows);
    }

    function setRadioTempoSalaMin(){        
        const rows = Object.keys(temposSalaMin).map(function(key) {    
            return <RadioButton disabled={disabled} label={temposSalaMin[key]} name="tempoSalaMin" checked={ tempoSalaMin === key } onClick={ () => setTempoSalaMin(key)}  />
        });
        setListaTempoSalaMin(rows);
    }



    async function getRegistro(){

        setShow(true);

        if (params.id) {

            try {
                
                let response;
                if(role === 'MEDICO'){
                    response = await comercialService.getOrcamentoUsuaurioById(params.id);
                }else {
                    response = await comercialService.getOrcamentoById(params.id);
                }
                
    
                //setNrSeqPacote(response.data.nr_seq_pacote);
                setDtNascimento(response.data.dt_nascimento ? response.data.dt_nascimento.substr(0, 10) : null);
                setNmPaciente(response.data.nm_paciente);
                setNrCpf(response.data.nr_cpf);
                setTelefone(response.data.telefone);
                setCdProcedimento(response.data.cd_procedimento);
                setDsProcedimento(response.data.ds_procedimento);
                setAnestesia(response.data.tipo_anestesia);
                setTempoSala(response.data.tempo_sala? String(response.data.tempo_sala): undefined);
                setTempoSalaMin(response.data.tempo_sala_min? String(response.data.tempo_sala_min): undefined);
                setAcomodacao(response.data.tipo_acomodacao? String(response.data.tipo_acomodacao): undefined);
                setDiaria(response.data.num_diarias? String(response.data.num_diarias): undefined);
                setDiariaUti(response.data.num_diarias_uti? String(response.data.num_diarias_uti): undefined);
                setReservaSangue(response.data.reserva_sangue);
                setPerneira(response.data.obs_perneira);
                setUsoOpme(response.data.uso_opme);
                setQtSangue(response.data.quantidade_sangue? String(response.data.quantidade_sangue): undefined);
                setObservacoes(response.data.observacao);             
                setDisabled(!response.data.orcamentoStatus.alterar);
                setNrSeqProcInterno(response.data.nr_seq_proc_interno);
                
                if(response.data.opmes && response.data.opmes.length > 0){
                    let values;
                    for(var i = 0; i < response.data.opmes.length; i++){
                        if(values){
                            values = [...values, { opme: response.data.opmes[i].opme, quantidade: response.data.opmes[i].quantidade, fornecedor: response.data.opmes[i].fornecedor}]
                        }else{
                            values = [{ opme: response.data.opmes[i].opme, quantidade: response.data.opmes[i].quantidade, fornecedor: response.data.opmes[i].fornecedor }]
                        }
                    }

                    setFormOpme(values)
                }

  
            } catch (error) {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao recuperar o registro para edição");
                const viewBack = JSON.parse(session.getSession(strings.viewBack));              
                history.push(viewBack ? viewBack : '/');  
            }
    
        }
    
        setShow(false);
    }
 
    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

        if(disabled){
            return false;
        }

        setShow(true);   

        e.preventDefault();
      
        const nm_usuario = JSON.parse(getStorage(strings.user));       

        let dtNascimento = `${dt_nascimento}T03:00:00.000Z`;

        const formData = {
            
            nm_paciente,
            nr_cpf: nr_cpf ? nr_cpf.replace(/([^\d])+/gim, '') : undefined,
            nm_usuario,
            dt_nascimento : dtNascimento,
            telefone,
            ds_procedimento,
            cd_procedimento,
            tipo_anestesia: anestesia,
            tipo_acomodacao: acomodacao,
            num_diarias: diaria,
            num_diarias_uti: diariaUti,
            reserva_sangue: reservaSangue,
            obs_perneira: perneira,
            uso_opme: usoOpme,
            observacao: observacoes,
            tempo_sala: tempoSala,
            tempo_sala_min: tempoSalaMin,
            quantidade_sangue: qtSangue,
            nr_seq_proc_interno,
            status_id: 'AO', //aguardando orcamento
            opmes: usoOpme === 'sim' ? formOpme : undefined
        }

        if(params.id){

            if(role === 'MEDICO'){
                comercialService.putOrcamentoUsuario(params.id, formData)
                .then(response => {
                    const sessionDoc = { nomePacienteSearch: response.data.nm_paciente };
                    session.setSession(strings.orcamentos, JSON.stringify(sessionDoc));
                    messages.mensagemSucesso("Registro alterado com sucesso");
                    history.push('/orcamentos');
                }).catch(error => {
                    messages.mensagemErro("Ocorreu um erro ao inserir o registro.");
                    console.log(error.response);
                }).finally(() =>
                    setShow(false)
                 ); 
            }else{
                comercialService.putOrcamento(params.id, formData)
                .then(response => {
                    const sessionDoc = { nomePacienteSearch: response.data.nm_paciente };
                    session.setSession(strings.orcamentos, JSON.stringify(sessionDoc));
                    messages.mensagemSucesso("Registro alterado com sucesso");
                    history.push('/comercialOrcamentos');
                }).catch(error => {
                    messages.mensagemErro("Ocorreu um erro ao inserir o registro.");
                    console.log(error.response);
                }).finally(() =>
                    setShow(false)
                 ); 
            }
           

        }else{            
            comercialService.postOrcamento(formData)
            .then(response => {
                const sessionDoc = { nomePacienteSearch: response.data.nm_paciente };
                session.setSession(strings.orcamentos, JSON.stringify(sessionDoc));
                messages.mensagemSucesso("Registro incluído com sucesso");
                if(role === 'MEDICO'){
                    history.push("/orcamentos");
                }else{
                    history.push("/comercialOrcamentos");
                }
            }).catch(error => {
                messages.mensagemErro("Ocorreu um erro ao inserir o registro.");
                console.log(error.response);
            }).finally(() =>
                setShow(false)
            ); 
        }
    }

    function handleClose(){
        const viewBack = JSON.parse(session.getSession(strings.viewBack));              
        history.push(viewBack ? viewBack : '/');        
    }

    let handleChangeOpme = (i, e) => {
        let newFormValues = [...formOpme];
        newFormValues[i][e.target.name] = e.target.value;
        setFormOpme(newFormValues);
     }
        
    
    let removeFormFields = (i) => {
        let newFormValues = [...formOpme];
        newFormValues.splice(i, 1);
        setFormOpme(newFormValues)
    }

    let addFormFields = () => {
        setFormOpme([...formOpme, { opme: "", quantidade: 0, fornecedor: "" }])
     }

    return (
        <div>
        <section className="content">
            <div className="container-fluid">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3 className="card-title">Dados da Solicitação</h3>
                    </div>
                    {/* /.card-header */}
                    {/* form start */}
                    <form onSubmit={handleSubmit}>
                        <div className="card-body">
                            <div className="row">
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="nm_paciente" label="Nome do Paciente*" htmlFor="nm_paciente">
                                    <input type="text" disabled={disabled} className="form-control" id="nm_paciente" required placeholder="digite o nome do paciente"
                                        name="nm_paciente" maxLength={300} value={nm_paciente} onChange={(e) => setNmPaciente(e.target.value)} />
                                </FormGroup>
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="nr_cpf" label="CPF" htmlFor="nr_cpf">
                                    <input type="text"  disabled={disabled} className="form-control" id="nm_paciente" placeholder="digite o CPF do paciente"
                                        name="nr_cpf" value={nr_cpf}  onChange={(e) => setNrCpf( cpfMask(e.target.value) )}  />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="dt_nascimento" label="Data de Nascimento*" htmlFor="dt_nascimento">
                                    <input type="date"  disabled={disabled} className="form-control" id="dt_nascimento" required 
                                        name="dt_nascimento" value={dt_nascimento} onChange={(e) => setDtNascimento(e.target.value)} />
                                </FormGroup>
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="telefone" label="Telefone de Contato" htmlFor="telefone">
                                    <input type="text"  disabled={disabled} className="form-control" id="telefone" placeholder="( ) _________"
                                        name="telefone" value={telefone}  onChange={(e) => setTelefone( e.target.value )}  />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="ds_procedimento" label="Procedimento*" htmlFor="ds_procedimento">
                                    <input type="text"  disabled={disabled} className="form-control" id="ds_procedimento" required 
                                        name="ds_procedimento" maxLength={300} placeholder="digite o nome do procedimento"  value={ds_procedimento} onChange={(e) => setDsProcedimento(e.target.value)} />
                                </FormGroup>
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="cd_procedimento" label="Código CBHPM" htmlFor="cd_procedimento">
                                    <input type="number"  disabled={disabled} className="form-control" id="cd_procedimento" 
                                        name="cd_procedimento" placeholder="digite o codigo CBHP do procedimento" value={cd_procedimento}  onChange={(e) => setCdProcedimento( e.target.value )}  />
                                </FormGroup>
                            </div>     
                            <div className="row">  
                                <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                    id="nr_seq_proc_interno" label="Proc. Interno" htmlFor="nr_seq_proc_interno">
                                    <input type="number"  disabled={disabled} className="form-control" id="nr_seq_proc_interno" 
                                        name="nr_seq_proc_interno" value={nr_seq_proc_interno}  onChange={(e) => setNrSeqProcInterno( e.target.value )}  />
                                </FormGroup>   
                            </div>                        
                            < hr />                   
                            <div className="col-sm-12">                                
                                <label>Anestesia</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaAnestesias}
                                </div>
                            </div>    
                            <div className="col-sm-12">                                
                                <label>Tempo de Sala (horas)</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaTempoSala}
                                </div>
                            </div>    
                            <div className="col-sm-12">                                
                                <label>Tempo de Sala (minutos)</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaTempoSalaMin}
                                </div>
                            </div>    
                            <div className="col-sm-12">                                
                                <label>Acomodação</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaAcomodacoes}
                                </div>
                            </div>    
                            <div className="col-sm-12">                                
                                <label>Número de Diarias</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaDiarias}
                                </div>
                            </div>    
                            <div className="col-sm-12">                                
                                <label>Número de Diarias UTI</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaDiariasUti}
                                </div>
                            </div> 
                            <div className="col-sm-12">                                
                                <label>Reserva de Sangue?</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaReservaSangue}
                                </div>
                            </div>         
                            {  reservaSangue === 'sim' &&                              
                                <div className="col-sm-12">                                
                                    <label>Reserva de sangue (Qtd de bolsas)</label>
                                    <div className="form-group clearfix" >                                                                   
                                        {listaQtSangue}
                                    </div>
                                </div>    
                            }      
                            <div className="row">
                                <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    id="perneira" label="Necessário o uso de perneira ou meias de compresão? Se sim, informar especificações...." htmlFor="perneira">
                                    <input type="text"  disabled={disabled} className="form-control" id="perneira" placeholder="especificações"
                                        name="perneira" maxLength={4000} value={perneira} onChange={(e) => setPerneira(e.target.value)} />
                                </FormGroup>                             
                             </div>   
                             <div className="col-sm-12">                                
                                <label>Fará uso de OPME?</label>
                                <div className="form-group clearfix" >                                                                   
                                    {listaUsoOpme}
                                </div>
                            </div>   
                          
                            { usoOpme === 'sim' && formOpme.map((element, index) => (

                                <div className="row">
                                <FormGroup className="form-group col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                                    id={`opme${index}`}  label="Opme" htmlFor="opme">                                   
                                       <input type="text"  disabled={disabled} className="form-control"
                                       name="opme" required value={element.opme || ""} onChange={e => handleChangeOpme(index, e)} />
                                </FormGroup>
                                <FormGroup className="form-group col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                                    id={`qtd${index}`}   label="Quantidade" htmlFor="quantidade">
                                    <input type="number" disabled={disabled} className="form-control" 
                                        name="quantidade" value={element.quantidade || ""} onChange={e => handleChangeOpme(index, e)} />
                                </FormGroup>
                                <FormGroup className="form-group col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                                    id={`fornecedor${index}`}label="Fornecedor"   htmlFor="fornecedor">
                                    <input type="text" disabled={disabled} className="form-control" 
                                        name="fornecedor" value={element.fornecedor || ""} onChange={e => handleChangeOpme(index, e)} />
                                </FormGroup>
                                                                      {
                                    index ? 
                                    <div className="form-group" style={{paddingTop: 33}}>
                                        <button type="button"  disabled={disabled} className="btn btn-default" style={{color:"red"}} onClick={() => removeFormFields(index)}>Remover</button> 
                                    </div> 
                                        : null
                                    
                                }
                                </div>                               
                            )                             
                            )                          
                            }     
                            { usoOpme === 'sim' &&
                                <div className="card-footer center" style={{paddingBottom: 10}}>
                                    <button  disabled={disabled} className="btn btn-default" style={{color:"blue"}} type="button" onClick={() => addFormFields()}>Adicionar nova linha</button>
                                </div>
                            }          
                            < hr />
                            <div className="form-group">
                                <label>Observações</label>
                                <textarea className="form-control"  disabled={disabled} rows={3} value={observacoes} onChange={(e) => setObservacoes(e.target.value)} placeholder="observações ..."></textarea>
                            </div>
                        </div> 
                        <div className="card-footer float-right">
                            <div className="row">
                                <div style={{ paddingRight: 10 }}>
                                    { !disabled &&
                                        <button type="button" className="btn btn-danger" onClick={() =>  {  if (window.confirm('Você tem certeza que deseja sair sem salvar o registro?')) handleClose() } }>Fechar</button>
                                    }
                                    { disabled &&
                                        <button type="button" className="btn btn-danger" onClick={() =>  handleClose() }>Fechar</button>
                                    }
                                </div>
                                { !disabled &&
                                    <button type="submit"  disabled={disabled} className="btn btn-primary">Salvar</button>
                                }
                                </div>
                        </div>
                    </form>
                </div>  {/*card-body*/}
            </div>
        </section>
    </div>
    );

}

export default OrcamentoForm;