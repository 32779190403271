import crypto from 'crypto-js';
const key = "@TI2021HSVP-CPD-aLl";


export function getSession(name: string){

    let value = null;
    const sessionValue = sessionStorage.getItem(name);

    if(sessionValue){
        const bytes  = crypto.AES.decrypt(sessionValue.toString(), key);
        value = bytes.toString(crypto.enc.Utf8);
    }

    return value;
}

export function setSession(name: string, object: string){

    const value = crypto.AES.encrypt(object, key);
    return sessionStorage.setItem(name, value.toString());
}

export function removeSession(name: string){
    return sessionStorage.removeItem(name);
}

export function removeAll(){
    return sessionStorage.clear();
}


export function setStorage(name:string, object:string){

    const value = crypto.AES.encrypt(object, key);
    localStorage.setItem(name, value);

}


export function getStorage(name: string){

    let value = null;
    const sessionValue = localStorage.getItem(name);

    if(sessionValue){
        const bytes  = crypto.AES.decrypt(sessionValue.toString(), key);
        value = bytes.toString(crypto.enc.Utf8);
    }

    return value;
}
