import { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ModalContext } from '../context/ModalContext';


export function ModalComponent() {
    const {show, setShowModal, modalTitle, message,setFinalizadoModal,  setConfirmModal } = useContext(ModalContext);
  
    function confirm(){
        setConfirmModal(true);
        setFinalizadoModal(true);
        setShowModal();
    }

    function handleClose(){
        setConfirmModal(false);
        setFinalizadoModal(true);
        setShowModal();
    }
  
    return (
      <> 
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={confirm}>
              Sim
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );


}

