import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div>
                <div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <div className="row">
                        <div className="col-11"><strong>Copyright © 2021 <a rel="noreferrer" target="_blank" href="http://hsvp.com.br">HSVP</a>.</strong> All rights reserved.</div>
                        <div className="col-1">
                            <b>Version</b> 0.0.1
                        </div>
                    </div>
                </footer>
                <div id="sidebar-overlay" />
                {/* ./wrapper */}
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
                </div>
            </div>
        )
    }
}
