
import { useContext } from 'react';
import { Context } from '../context/AuthContext';

export default function Header()  {
    const { authenticated, handleLogout } = useContext(Context);

        if(authenticated){
            return (
                <div>
                    <nav className="main-header navbar navbar-expand navbar-dark navbar-primary">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link sidebar-toggle-btn" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        {/* Notifications Dropdown Menu */}
                        <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="void::javascript">
                            <i className="far fa-bell" />
                            <span className="badge badge-warning navbar-badge"></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header"> </span>
                            <div className="dropdown-divider" />
                            <a href="void::javascript" className="dropdown-item">
                            <i className="fas fa-envelope mr-2" />
                            <span className="float-right text-muted text-sm"></span>
                            </a>
                        </div>
                        </li>
                        <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="void::javascript">
                            <i className="far fa-user" />              
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">Usuário</span>
                            <div className="dropdown-divider" />            
                            <div className="dropdown-divider" />
                            <a href="void::javascript" className="dropdown-item">
                            <i className="fas fa-users mr-2" /> Perfil
                            </a>   
                            <a href="void::javascript" onClick={handleLogout} className="dropdown-item">
                            <i className="fa fa-window-close mr-2" /> Sair
                            </a>              
                        </div>
                        </li>
                    </ul>
                    </nav>
                </div>
            );
        }else{
            return (
            <></>
        );
   }
    
}
