import { useContext, useEffect, useState } from "react";
import FormGroup from "../../components/FormGroup";
import { LoaderContext } from "../../context/LoaderContext";
import ComercialService from "../../services/comercial/comercialService";
import Select from 'react-select';
import { Estabelecimentos } from "../../utils/estabelecimentos";
import comercialGetFile from "../../utils/comercialGetFile";
import { getOrcamentoStatus } from "../../utils/getSelects";
import { FilterOrcamento } from "../../interfaces/orcamentoInterface";
import * as session from '../../utils/sessionStorage';
import { strings } from "../../utils/strings";
import history from "../../history";
import { ModalContext } from "../../context/ModalContext";
import * as messages from '../../components/Toastr';
import { Button, Modal } from "react-bootstrap";
import AgendamentoOrcamentosTable from "./agendamentoOrcamentosTable";
import CheckButton from "../../components/CheckButton";




function AgendamentoOrcamentos(){

    const comercialService = new ComercialService();

    const { setShow } = useContext(LoaderContext);

    const [rows, setRows] = useState([]);
    const [nomePacienteSearch, setNomePacienteSearch] = useState<string>(getSearch("paciente"));
    const [estabelecimentoSearch, setEstabelecimentoSearch] = useState<any>(getSearch("estab"));
    const [dtAgendaSearch, setDtAgendaSearch] = useState<string>(getSearch("agenda"));
    const [statusSearch, setStatusSearch] = useState<Array<string>>(getSearch("status"));
    const [statusListCheck, setStatusListCheck] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [idCan, setIdcan] = useState<string>();
    const [statusCan, setStatusCan] = useState<string>();
    const [showModalAgenda, setShowModalAgenda] = useState(false);
    const [estabelecimento, setEstabelecimento] = useState<any>();
    const [dtAgenda, setDtAgenda] = useState<string>('');
    const [hrAgenda, setHrAgenda] = useState<string>('');
    const [observacao, setObservacao] = useState<string>();
    const [observacaoComercial, setObservacaoComercial] = useState<string>();
    const [observacaoAgenda, setObservacaoAgenda] = useState<string>();
    const [valEstab, setValEstab] = useState<string>();
    const [valDtAgenda, setValDtAgenda] = useState<string>();
    const [valHrAgenda, setValHrAgenda] = useState<string>();
    const [zIndex, setZIndex] = useState(10000);
    const [orcamentoId, setOrcamentoId] = useState<string>();
    const [modalTitle, setModalTitle] = useState<string>();
    const [nm_paciente, setNmPaciente] = useState<string>();
    const [ds_procedimento, setDsProcedimento] = useState<string>();
    const [removerArquivo, setRemoverArquivo] = useState<boolean>();
    const [ds_estabelecimento, setDsEstabelecimento] = useState<string>();
    const [request, setRequest] = useState<boolean>();
    const [nr_seq_proc_interno, setProcInterno] = useState<string>(getSearch("procInterno"));
    const [time, setTime] = useState(0);

    const { setShowModal, setTitle, setConfirmModal, setMessageModal, setFinalizadoModal, finalizado, confirm } = useContext(ModalContext);

    const estabelecimentos = Estabelecimentos;

    let countdownTimeout: NodeJS.Timeout;


    useEffect(() => {
        getProceds();        
    }, []);

    useEffect( () => {
        if(!request){
            countdownTimeout = setTimeout( () => {
                setTime(time + 1);
            }, 300000);
        }
    }, [time]);

    useEffect(() => {
        setSearchSession();
        getProceds();        
    }, [time]);

    useEffect(() => {
        getUpdate();        
    }, [finalizado, confirm]);

    useEffect(() => {
        setStatusCheck();        
    }, [statusList, statusSearch]);

    function getUpdate() {
        if (confirm) {
            inativarItem();
        }
   }

   function setStatusClicked(statusId){        
        if(statusSearch.find(element => element === statusId)){
            const array = statusSearch;
            const index = array.indexOf(statusId);
            if (index > -1) {
                array.splice(index, 1);
            }
            setStatusSearch(array);   
            setStatusCheck();

        }else{
            setStatusSearch([...statusSearch, statusId]);
            //setStatusCheck();
        }
   }

   function setStatusCheck(){  
        const rows = statusList.map(function(key) {    
            return <CheckButton disabled={false} label={key.label} name={key.label} checked={ statusSearch.find(element => element === key.value)} onClick={ () => setStatusClicked(key.value)}  />
        });
        setStatusListCheck(rows);     
    }

    function handleClose(){
        setShowModalAgenda(false);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

        setShow(true);   
        setZIndex(100);

        e.preventDefault();

        const dt = `${dtAgenda} ${hrAgenda}:00`;
        var formData = new FormData();
        formData.append('observacaoAgenda', observacaoAgenda);
        formData.append('status_id', 'A');
        formData.append('dt_agenda', dt);

        comercialService.patchConfirmarOrcamento(orcamentoId, formData)
        .then(response => {           
            handleClose();
            getProceds();
            messages.mensagemSucesso("Agendamento gerado com sucesso.");
        }).catch(error => {
            messages.mensagemErro("Ocorreu um erro ao confirmar o registro.");
            console.log(error.response);
        }).finally(() =>
           finallyModal()
         );
    }
    function finallyModal(){
        setShow(false);
        setZIndex(10000);
    }

    function validarFields(field){
        if(field === 'estab'){
            if(!estabelecimento){
                setValEstab('Informe a unidade.');
            }        
        } else if(field === 'dtAgenda'){
            if(!dtAgenda){
                setValDtAgenda('Informe a data de agendamento.');
            }
        } else if(field === 'hrAgenda'){
            if(!hrAgenda){
                setValHrAgenda('Informe a hora de agendamento.');
            }
        }
    }
    
    function setSelect(e, type){

        if(type === 'estab'){
            setEstabelecimento(e);
            if(valEstab){
                setValEstab(undefined);
            }    
        }else if(type === 'dtAgenda'){
            setDtAgenda(e.target.value);
            if(valDtAgenda){
                setValDtAgenda(undefined);
            }
        }else if(type === 'hrAgenda'){
            setHrAgenda(e.target.value);
            if(valHrAgenda){
                setValHrAgenda(undefined);
            }
        }
       
    }

    async function getProceds() {

        if(!request){

            setShow(true);
            setRequest(true);

            if (!statusList || statusList.length < 1) {
                const statusList = await getOrcamentoStatus();
                setStatusList(statusList);
            }
    
            const filter: FilterOrcamento = {
                cd_estabelecimento: estabelecimentoSearch ? estabelecimentoSearch.value : undefined,
                nm_paciente: nomePacienteSearch ? nomePacienteSearch.replaceAll(" ", "+") : undefined,
                dt_agenda: dtAgendaSearch,
                status: statusSearch,
                nr_seq_proc_interno
                
            }    
        
            comercialService.getOrcamentos(filter)
                .then(response => {
                    setRows(response.data.orcamentos);
                })
                .catch(error => {
                    console.log(error.response);
                })
                .finally(() =>
                   finalRequest()
                ); 

        }
      
    }

    function finalRequest(){
        setShow(false)
        setRequest(false)
    }
    
    function setSearchSession() {
        const search = {
            estabelecimentoSearch,
            nomePacienteSearch,
            dtAgendaSearch,
            statusSearch,
            nr_seq_proc_interno
        }
        session.setSession(strings.orcamentos, JSON.stringify(search));
    }

    function getSearch(type: string) {
        const search = JSON.parse(session.getSession(strings.orcamentos));
        let field;

        if (search) {
            switch (type) {
                case "estab":
                    field = search.estabelecimentoSearch;
                    break;
                case "paciente":
                    field = search.nomePacienteSearch;
                    break;
                case "agenda":
                    field = search.dtAgendaSearch;
                    break;
                case "status":
                    field = search.statusSearch ? search.statusSearch : setFirstStatus();
                    break;   
                case "procInterno":
                    field = search.nr_seq_proc_interno;
                    break;              
                default:
                    break;
            }            
        }else{
            if(type === 'status'){
                field = setFirstStatus(); 
            }
        }

        return field;
    }

    function setFirstStatus(){
        return ['PA'];
    }

    function handleSubmitSearch(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        setSearchSession();        
        getProceds();
    }


    function inativarItem() {

        setShow(true);

        const documento = {
            status_id: statusCan,
            removerArquivo
        }

        comercialService.patchOrcamento(idCan, documento)
            .then(response => {
                messages.mensagemSucesso("Status alterado com sucesso");
                getProceds();

            })
            .catch(error => {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao tentar alterar o status do registro.")
            })
            .finally(() =>
                finalizarStatusItem()
            );


    }

    function finalizarStatusItem(){
        setConfirmModal(false);
        setFinalizadoModal(false);
        setShow(false)
        getProceds();
    }


    function inativar(id: string, status: string, messagem: string, removerArquivo: boolean) {

        setIdcan(id);
        setStatusCan(status);
        setRemoverArquivo(removerArquivo);

        setTitle("Confirmação");
        setMessageModal(messagem);
        setShowModal();

    }

    function visualizar(id:string){
        const viewBack = '/agendamentoOrcamentos';
        session.setSession(strings.viewBack, JSON.stringify(viewBack));
        history.push(`/orcamentos/editar/${id}`);
    }

    function confirmarAgendamento(id:string, nm_paciente:string, ds_procedimento: string, dsObservacao: string, cd_estabelecimento: number, dt_agenda_orcamento: string, dsObservacaoComercial: string, dsObservacaoAgenda: string){

        const estabFilter = estabelecimentos.filter(function(estabelecimento) {
            return estabelecimento.value === cd_estabelecimento;
        });

        const dt = new Date(dt_agenda_orcamento);
        const month = dt.getMonth() + 1;
        const dataFormatada = `${dt.getFullYear()}-${month.toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`;
        const horaFormatada = `${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
      
        setDsEstabelecimento(estabFilter[0].label);
        setDtAgenda(dataFormatada);
        setHrAgenda(horaFormatada);

        setModalTitle('Agendar');
        setOrcamentoId(id);
        setNmPaciente(nm_paciente);
        setDsProcedimento(ds_procedimento);
        setObservacaoComercial(dsObservacaoComercial);
        setObservacaoAgenda(dsObservacaoAgenda);
        setObservacao(dsObservacao);
        setShowModalAgenda(true);
    }

    async function getFile(name: string, id: string, fileName: string) {
        setShow(true);
        await comercialGetFile(name, id, fileName, 'orcamento');
        setShow(false);        
    }
     

    return (
        <div>
            <div className="form-horizontal">
                <div className="card-body">
                    <form onSubmit={handleSubmitSearch}>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputDescricao" label="Nome do Paciente" htmlFor="descricao">
                                <input type="text" className="form-control" id="descricao" placeholder="digite o nome do paciente"
                                    name="descricao" value={nomePacienteSearch} onChange={(e) => setNomePacienteSearch(e.target.value)} />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                 id="inputEstab" label="Selecione o Hospital" htmlFor="estabelecimento">
                                 <Select isClearable={true}
                                 placeholder="Seleciona a unidade..." name="cd_estabelecimento" 
                                 options={estabelecimentos} value={estabelecimentoSearch} onChange={(e) => setEstabelecimentoSearch(e)} />
                            </FormGroup>
                        </div>       
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                 id="inputDtAgenda" label="Data de Agendamento" htmlFor="inputDtAgenda">
                                 <input type="date" className="form-control" id="dtAgenda"
                                     name="dtAgenda" value={dtAgendaSearch} onChange={(e) => setDtAgendaSearch(e.target.value)}  />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputProcInterno" label="Código Proc Interno" htmlFor="procInterno">
                                <input type="text" className="form-control" id="nr_seq_proc_interno" placeholder="digite o proc interno"
                                    name="nr_seq_proc_interno" value={nr_seq_proc_interno} onChange={(e) => setProcInterno(e.target.value)} />
                            </FormGroup>
                        </div>  
                        <div className="row">
                            <div className="col-sm-12">                                
                                    <label>Status</label>
                                    <div className="form-group" >                                                                   
                                        {statusListCheck}
                                    </div>
                            </div>                           
                        </div>                  
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary float-right">Pesquisar</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card">
               
                {/* /.card-header */}
                <div className="card-body">
                    <AgendamentoOrcamentosTable orcamentos={rows} getFile={getFile}  inativar={inativar} visualizar={visualizar} confirmarAgendamento={confirmarAgendamento}  />
                </div>
            </div>

            <Modal  size="lg" style={{zIndex}} aria-labelledby="contained-modal-title-vcenter" centered show={showModalAgenda} onHide={ () => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } }>
            <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>           
            <Modal.Body>               
                <div className="card">                  
                    <div className="card-header" style={{background:"#e6e6ff"}}>
                        <strong>{modalTitle}</strong>
                    </div>
                    <hr />    
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputPaciente" label="Paciente" htmlFor="paciente">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="paciente"
                                name="nm_paciente" value={nm_paciente}  />                                
                        </FormGroup>
                    </div>      
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputProcedimento" label="Procedimento" htmlFor="procedimento">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="ds_procedimento"
                                name="ds_procedimento" value={ds_procedimento}  />                                
                        </FormGroup>
                    </div>           
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacaoMedico" label="Observacao Médico" htmlFor="observacao">
                        <textarea autoComplete="off" className="form-control" id="observacaoMedico" disabled value={observacao}
                        rows={3}></textarea>
                        </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacaoComercial" label="Observacao Comercial" htmlFor="observacaoComercial">
                        <textarea autoComplete="off" className="form-control" id="observacaoComercial" disabled value={observacaoComercial}
                        rows={3}></textarea>
                        </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputEstab" label="Selecione o Hospital*" htmlFor="estabelecimento">
                            <input type="text" autoComplete="off"  disabled className="form-control" id="ds_estabelecimento"
                                name="ds_procedimento" value={ds_estabelecimento}  />        
                        </FormGroup>
                    </div>                  
                    <div className="row">
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputDtAgenda" label="Data Prevista de Agendamento*" htmlFor="inputDtAgenda">
                            <input type="date" autoComplete="off" className="form-control" id="dtAgenda"
                                name="dtAgenda" required value={dtAgenda} onBlur={() => validarFields('dtAgenda')} onChange={(e) => setSelect(e, 'dtAgenda')}  />
                            <span style={{color: "red"}}>{valDtAgenda}</span>
                        </FormGroup>    
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputHrAgenda" label="Hora de Agendamento*" htmlFor="inputHrAgenda">
                            <input type="time" autoComplete="off" className="form-control" id="dtAgenda"
                                name="hrAgenda" required value={hrAgenda} onBlur={() => validarFields('hrAgenda')} onChange={(e) => setSelect(e, 'hrAgenda')}  />
                                <span style={{color: "red"}}>{valHrAgenda}</span>
                        </FormGroup>    
                    </div>                 
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacao" label="Observacao Agendamento" htmlFor="observacao">
                        <textarea autoComplete="off" className="form-control" id="observacao" value={observacaoAgenda}
                        onChange={(e) => setObservacaoAgenda(e.target.value)} rows={3}></textarea>
                        </FormGroup>
                    </div>
                </div>      

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary"  onClick={() => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } } >
                Fechar
            </Button>
            <Button type="submit" variant="primary">
                Confirmar
            </Button>           
            </Modal.Footer>
            </form>
            </Modal>

        </div>

    );


}
export default AgendamentoOrcamentos;