
import {DocumentosInterface} from '../../interfaces/documentInterface';

export default function PacotesTable(props: Props){

   
    const array =  Object.values(props.documentos);    
    const rows = array.map( documento => {
        return(             
            <tr key={documento.id}>       
                <td><b>{documento.descricao.toUpperCase()}</b></td> 
                <td>{documento.nr_seq_proc_interno}</td>    
                <td>
                    <button onClick={ () => props.handleModal(documento.id, documento.descricao, documento.nr_seq_proc_interno, documento.arquivo)} type="button" className="btn btn-success float-right" style={{marginRight: "5px"}}><i className="fas fa-calendar-plus"></i> Agendar</button>
                </td>                                                                    
                <td>
                    <button onClick={ () => props.getFile(documento.descricao, documento.id, documento.arquivo, 'pacote')} type="button" className="btn btn-primary float-right" style={{marginRight: "5px"}}><i className="fas fa-download"></i> Visualizar</button>
                </td>
            </tr>
        )
    });
    


    return (

        <table className="table table-striped">
            <thead>
                <tr>
                    <th style={{width: "70%"}}>Descrição</th>    
                    <th>Código</th>                          
                    <th></th> 
                    <th></th>                       
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>

    );


}

interface Props{
    documentos: DocumentosInterface;
    getFile: (name: string, id:string, fileName:string, tipo: string) => void;
    handleModal: (id:string, name:string, nr_seq_proc_interno: number, arquivo: string) => void;
}