
import { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { LoaderContext } from "../context/LoaderContext";

export default function LoaderSpinner(){

    const height = Number(window.innerHeight);
    const {loading } = useContext(LoaderContext);
 

    return (
        loading ? 
        <div className="spinner" style={{zIndex: 1000, position: "absolute", height, width:"100%", background: "gray", opacity: "0.5"}}>
            <div className="d-flex justify-content-center" style={{padding: "50px"}} >
                <Spinner animation="border" role="status" />   
                <p></p>                   
                <p style={{paddingLeft: "10px", fontStyle: "bold"}}>Carregando, por favor aguarde...</p>
            </div>
        </div>  
        :
        <></>
    )

}

