import { useContext, useEffect, useState } from "react";
import FormGroup from "../../components/FormGroup";
import { LoaderContext } from "../../context/LoaderContext";
import ComercialService from "../../services/comercial/comercialService";
import Select from 'react-select';
import { Estabelecimentos } from "../../utils/estabelecimentos";
import OrcamentosTables from "./orcamentosTable";
import comercialGetFile from "../../utils/comercialGetFile";
import { getOrcamentoStatus } from "../../utils/getSelects";
import { FilterOrcamento } from "../../interfaces/orcamentoInterface";
import * as session from '../../utils/sessionStorage';
import { strings } from "../../utils/strings";
import history from "../../history";
import { ModalContext } from "../../context/ModalContext";
import * as messages from '../../components/Toastr';
import { Button, Modal } from "react-bootstrap";
import CheckButton from "../../components/CheckButton";





function Orcamentos(){

    const comercialService = new ComercialService();

    const { setShow } = useContext(LoaderContext);

    const [rows, setRows] = useState([]);
    const [nomePacienteSearch, setNomePacienteSearch] = useState<string>(getSearch("paciente"));
    const [estabelecimentoSearch, setEstabelecimentoSearch] = useState<any>(getSearch("estab"));
    const [dtAgendaSearch, setDtAgendaSearch] = useState<string>(getSearch("agenda"));
    const [statusSearch, setStatusSearch] = useState<Array<string>>(getSearch("status"));
    const [nrSeqProcInternoSearch, setNrSeqProcInternoSearch] = useState<any>(getSearch("procInterno"));
    const [statusList, setStatusList] = useState([]);
    const [statusListCheck, setStatusListCheck] = useState([]);
    const [idCan, setIdcan] = useState<string>();
    const [statusCan, setStatusCan] = useState<string>();
    const [showModalAgenda, setShowModalAgenda] = useState(false);
    const [showModalRecusa, setShowModalRecusa] = useState(false);
    const [estabelecimento, setEstabelecimento] = useState<any>();
    const [dtAgenda, setDtAgenda] = useState<string>('');
    const [hrAgenda, setHrAgenda] = useState<string>('');
    const [equipe, setEquipe] = useState<string>();
    const [equipamento, setEquipamento] = useState<string>();
    const [observacao, setObservacao] = useState<string>();
    const [observacaoComercial, setObservacaoComecial] = useState<string>();
    const [valEstab, setValEstab] = useState<string>();
    const [valDtAgenda, setValDtAgenda] = useState<string>();
    const [valHrAgenda, setValHrAgenda] = useState<string>();
    const [zIndex, setZIndex] = useState(10000);
    const [orcamentoId, setOrcamentoId] = useState<string>();
    const [nm_paciente, setNmPaciente] = useState<string>();
    const [ds_procedimento, setDsProcedimento] = useState<string>();
    const [request, setRequest] = useState<boolean>();
    const [time, setTime] = useState(0);

    let countdownTimeout: NodeJS.Timeout;

    const { setShowModal, setTitle, setConfirmModal, setMessageModal, setFinalizadoModal, finalizado, confirm } = useContext(ModalContext);

    const estabelecimentos = Estabelecimentos;

    useEffect( () => {
        if(!request){
            countdownTimeout = setTimeout( () => {
                setTime(time + 1);
            }, 300000);
        }
    }, [time]);

    useEffect(() => {
        setSearchSession();
        getProceds();        
    }, [time]);

    useEffect(() => {
        getProceds();
    }, []);

    useEffect(() => {
        getUpdate();        
    }, [finalizado, confirm]);

    useEffect(() => {
        setStatusCheck();        
    }, [statusList, statusSearch]);

    function getUpdate() {
        if (confirm) {
            inativarItem();
            getProceds();
        }
   }

    function handleClose(){
        setShowModalAgenda(false);
        setShowModalRecusa(false);
    }

    function setStatusClicked(statusId){        
        if(statusSearch.find(element => element === statusId)){
            const array = statusSearch;
            const index = array.indexOf(statusId);
            if (index > -1) {
                array.splice(index, 1);
            }
            setStatusSearch(array);   
            setStatusCheck();

        }else{
            setStatusSearch([...statusSearch, statusId]);            
        }
   }

   function setStatusCheck(){  
        const rows = statusList.map(function(key) {    
            return <CheckButton disabled={false} label={key.label} name={key.label} checked={ statusSearch.find(element => element === key.value)} onClick={ () => setStatusClicked(key.value)}  />
        });
        setStatusListCheck(rows);     
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

        setShow(true);   
        setZIndex(100);

        e.preventDefault();

        if(!estabelecimento){
            if(!valEstab){
                setValEstab('Informe a unidade');
            }
            finallyModal();
            return false;
        }

        const dataAtual = new Date();
        const dataAgenda = new Date(dtAgenda)
        dataAgenda.setHours(dataAgenda.getHours() + 24);
        dataAtual.setHours(0,0,0,0);

        if(dataAgenda < dataAtual){
            if(!valDtAgenda){
                setValDtAgenda('Data de agendamento não pode ser menor que a data atual.');
            }
            finallyModal();
            return false;
        }

        const dt = `${dtAgenda} ${hrAgenda}:00`;

       /* const formData = {
            observacao,
            dt_agenda : dt,
            ds_equipe_anest : equipe,
            ds_equipamento : equipamento,
            status_id : 'PA', //pendente agendamento table: orcamento_status
            cd_estabelecimento: estabelecimento ? estabelecimento.value : undefined
        }*/

        var formData = new FormData();
        formData.append('observacao', observacao);
        formData.append('dt_agenda', dt);
        formData.append('ds_equipe_anest', equipe);
        formData.append('ds_equipamento', equipamento);
        formData.append('status_id', 'PA');
        formData.append('cd_estabelecimento',  estabelecimento ? estabelecimento.value : undefined);


        comercialService.patchConfirmarOrcamento(orcamentoId, formData)
        .then(response => {           
            handleClose();
            getProceds();
            messages.mensagemSucesso("Orçamento confirmado com sucesso.");
        }).catch(error => {
            messages.mensagemErro("Ocorreu um erro ao confirmar o registro.");
            console.log(error.response);
        }).finally(() =>
           finallyModal()
         );
    }
    function finallyModal(){
        setShow(false);
        setZIndex(10000);
    }

    function handleSubmitRecusa(e: React.FormEvent<HTMLFormElement>) {

        setShow(true);   
        setZIndex(100);

        e.preventDefault();      
              
        var formData = new FormData();
        formData.append('observacao', observacao);       ;
        formData.append('status_id', 'R');
       
        comercialService.patchConfirmarOrcamento(orcamentoId, formData)
        .then(response => {           
            handleClose();
            getProceds();
            messages.mensagemSucesso("Orçamento recusado com sucesso.");
        }).catch(error => {
            messages.mensagemErro("Ocorreu um erro ao recusar o registro.");
            console.log(error.response);
        }).finally(() =>
           finallyModal()
         );
    }
   
    function validarFields(field){
        if(field === 'estab'){
            if(!estabelecimento){
                setValEstab('Informe a unidade.');
            }        
        } else if(field === 'dtAgenda'){
            if(!dtAgenda){
                setValDtAgenda('Informe a data de agendamento.');
            }
        } else if(field === 'hrAgenda'){
            if(!hrAgenda){
                setValHrAgenda('Informe a hora de agendamento.');
            }
        }
    }
    
    function setSelect(e, type){

        if(type === 'estab'){
            setEstabelecimento(e);
            if(valEstab){
                setValEstab(undefined);
            }    
        }else if(type === 'dtAgenda'){
            setDtAgenda(e.target.value);
            if(valDtAgenda){
                setValDtAgenda(undefined);
            }
        }else if(type === 'hrAgenda'){
            setHrAgenda(e.target.value);
            if(valHrAgenda){
                setValHrAgenda(undefined);
            }
        }
       
    }

    async function getProceds() {

        if(!request){

            setShow(true);

            if (!statusList || statusList.length < 1) {
                const statusList = await getOrcamentoStatus();
                setStatusList(statusList);
            }

            const filter: FilterOrcamento = {
                cd_estabelecimento: estabelecimentoSearch ? estabelecimentoSearch.value : undefined,
                nm_paciente: nomePacienteSearch ? nomePacienteSearch.replaceAll(" ", "+") : undefined,
                dt_agenda: dtAgendaSearch,
                status: statusSearch,
                nr_seq_proc_interno: nrSeqProcInternoSearch
                
            }

        
            comercialService.getOrcamentosUsuario(filter)
                .then(response => {
                    setRows(response.data.orcamentos);
                })
                .catch(error => {
                    console.log(error.response);
                })
                .finally(() =>
                    finalRequest()
                );

        }

    }

    function finalRequest(){
        setShow(false)
        setRequest(false)
    }
    
    function setSearchSession() {
        const search = {
            estabelecimentoSearch,
            nomePacienteSearch,
            dtAgendaSearch,
            statusSearch
        }

        session.setSession(strings.orcamentos, JSON.stringify(search));
    }

    function setFirstStatus(){
        return ['AO', 'R', 'OE', 'PA'];
    }

    function getSearch(type: string) {
        const search = JSON.parse(session.getSession(strings.orcamentos));
        let field;

        if (search) {
            switch (type) {
                case "estab":
                    field = search.estabelecimentoSearch;
                    break;
                case "paciente":
                    field = search.nomePacienteSearch;
                    break;
                case "agenda":
                    field = search.dtAgendaSearch;
                    break;
                case "status":
                    field = search.statusSearch ? search.statusSearch : setFirstStatus();
                    break;  
                case "procInterno":
                    field = search.nr_seq_proc_interno;
                    break;             
                default:
                    break;
            }

        } else{
            if(type === 'status'){
                field = setFirstStatus(); 
            }
        }
        return field;
    }

    function handleSubmitSearch(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        setSearchSession();        
        getProceds();
    }

    async function getFile(name: string, id: string, fileName: string, tipo: string) {
        setShow(true);
        await comercialGetFile(name, id, fileName, tipo);
        setShow(false);        
    }

    function editar(id:string){
        const viewBack = '/orcamentos';
        session.setSession(strings.viewBack, JSON.stringify(viewBack));
        history.push(`/orcamentos/editar/${id}`);
    }



    function inativarItem() {

        setShow(true);

        const documento = {
            status_id: statusCan
        }

        comercialService.patchOrcamento(idCan, documento)
            .then(response => {
                messages.mensagemSucesso("Status alterado com sucesso");
                getProceds();

            })
            .catch(error => {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao tentar alterar o status do registro.")
            })
            .finally(() =>
                setShow(false)
            );

        setFinalizadoModal(false);
        setConfirmModal(false);
    }


    function inativar(id: string, status: string, name: string) {

        setIdcan(id);
        setStatusCan(status);

        setTitle("Confirmação");
        setMessageModal(`Confirma a alteração do registro: ${name}?`);
        setShowModal();

    }

    function visualizar(id:string){
        const viewBack = '/orcamentos';
        session.setSession(strings.viewBack, JSON.stringify(viewBack));
        history.push(`/orcamentos/editar/${id}`);
    }

    function adicionar(){
        const viewBack = '/orcamentos';
        session.setSession(strings.viewBack, JSON.stringify(viewBack));
        history.push(`/orcamentos/adicionar`);
    }

    function confirmar(id:string, dsoOservacao:string, nmPaciente:string, dsProcedimento:string, dsObservacaoComecial: string){
        setOrcamentoId(id);
        setObservacao(dsoOservacao);
        setObservacaoComecial(dsObservacaoComecial);
        setNmPaciente(nmPaciente);
        setDsProcedimento(dsProcedimento);
        setDtAgenda('');
        setHrAgenda('');
        setEstabelecimento('');
        setEquipamento('');
        setEquipe('');
        setShowModalAgenda(true);
    }

    function recusar(id:string, dsoOservacao:string, nmPaciente:string, dsProcedimento:string, dsObservacaoComecial: string){
        setOrcamentoId(id);
        setObservacao(dsoOservacao);
        setObservacaoComecial(dsObservacaoComecial);
        setNmPaciente(nmPaciente);
        setDsProcedimento(dsProcedimento);
        setShowModalRecusa(true);
    }


    return (
        <div>
            <div className="form-horizontal">
                <div className="card-body">
                    <form onSubmit={handleSubmitSearch}>
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputDescricao" label="Nome do Paciente" htmlFor="descricao">
                                <input type="text" className="form-control" id="descricao" placeholder="digite o nome do paciente"
                                    name="descricao" value={nomePacienteSearch} onChange={(e) => setNomePacienteSearch(e.target.value)} />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                 id="inputEstab" label="Selecione o Hospital" htmlFor="estabelecimento">
                                 <Select isClearable={true}
                                 placeholder="Seleciona a unidade..." name="cd_estabelecimento" 
                                 options={estabelecimentos} value={estabelecimentoSearch} onChange={(e) => setEstabelecimentoSearch(e)} />
                            </FormGroup>
                        </div>       
                        <div className="row">
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                 id="inputDtAgenda" label="Data de Agendamento" htmlFor="inputDtAgenda">
                                 <input type="date" className="form-control" id="dtAgenda"
                                     name="dtAgenda" value={dtAgendaSearch} onChange={(e) => setDtAgendaSearch(e.target.value)}  />
                            </FormGroup>
                            <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                id="inputProcInterno" label="Código Proc Interno" htmlFor="procInterno">
                                <input type="text" className="form-control" id="nr_seq_proc_interno_search" placeholder="digite o proc interno"
                                    name="nr_seq_proc_interno_search" value={nrSeqProcInternoSearch} onChange={(e) => setNrSeqProcInternoSearch(e.target.value)} />
                            </FormGroup>
                        </div>    
                        <div className="row">
                            <div className="col-sm-12">                                
                                    <label>Status</label>
                                    <div className="form-group clearfix" >                                                                   
                                        {statusListCheck}
                                    </div>
                            </div>                           
                        </div>                     
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary float-right">Pesquisar</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card">
                <div className="card-header">
                    <h3 className="card-title"> </h3>
                    <button onClick={adicionar} className="btn btn-success float-right" style={{margin: "20px"}}> <i className="fa fa-plus"></i> Solicitar Orçamento</button>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <OrcamentosTables orcamentos={rows} getFile={getFile} editar={editar} inativar={inativar} visualizar={visualizar} confirmar={confirmar} recusar={recusar}/>
                </div>
            </div>

            <Modal  size="lg" style={{zIndex}} aria-labelledby="contained-modal-title-vcenter" centered show={showModalAgenda} onHide={ () => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } }>
            <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
            <Modal.Title>Solicitar Agendamento</Modal.Title>
            </Modal.Header>           
            <Modal.Body>               
                <div className="card">                  
                    <div className="card-header" style={{background:"#e6e6ff"}}>
                        <strong>Dados Pré-agendamento</strong>
                    </div>
                    <hr />   
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputPaciente" label="Paciente" htmlFor="paciente">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="paciente"
                                name="nm_paciente" value={nm_paciente}  />                                
                        </FormGroup>
                    </div>      
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputProcedimento" label="Procedimento" htmlFor="procedimento">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="ds_procedimento"
                                name="ds_procedimento" value={ds_procedimento}  />                                
                        </FormGroup>
                    </div>     
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacaoComercial" label="Observacao Comercial" htmlFor="observacaoComercial">
                        <textarea autoComplete="off" disabled className="form-control" id="observacaoComercial" value={observacaoComercial}
                         rows={3}></textarea>
                        </FormGroup>
                    </div>                                
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputEstab" label="Selecione o Hospital*" htmlFor="estabelecimento">
                            <Select isClearable={true}
                            placeholder="Seleciona a unidade..." name="cd_estabelecimento" 
                            options={estabelecimentos} value={estabelecimento} onBlur={() => validarFields('estab')} onChange={(e) => setSelect(e, 'estab')} />
                            <span style={{color: "red"}}>{valEstab}</span>
                        </FormGroup>
                    </div>
                    <div className="row">
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputDtAgenda" label="Data Prevista de Agendamento*" htmlFor="inputDtAgenda">
                            <input type="date" autoComplete="off" className="form-control" id="dtAgenda"
                                name="dtAgenda" required value={dtAgenda} onBlur={() => validarFields('dtAgenda')} onChange={(e) => setSelect(e, 'dtAgenda')}  />
                            <span style={{color: "red"}}>{valDtAgenda}</span>
                        </FormGroup>    
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputHrAgenda" label="Hora de Agendamento*" htmlFor="inputHrAgenda">
                            <input type="time" autoComplete="off" className="form-control" id="dtAgenda"
                                name="hrAgenda" required value={hrAgenda} onBlur={() => validarFields('hrAgenda')} onChange={(e) => setSelect(e, 'hrAgenda')}  />
                                <span style={{color: "red"}}>{valHrAgenda}</span>
                        </FormGroup>    
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputEquipe" label="Equipe Anestésica" htmlFor="equipe">
                        <input type="text" autoComplete="off" className="form-control" id="equipe"  placeholder="digite o nome da equipe"
                            name="equipe" maxLength={100} value={equipe} onChange={(e) => setEquipe(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputEquipamantos"  label="Equipamentos" htmlFor="equipamentos">
                        <input type="text" autoComplete="off" className="form-control" id="equipamentos"  placeholder="digite os equipamentos"
                            name="equipamentos" maxLength={1000} value={equipamento} onChange={(e) => setEquipamento(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacao" label="Observacao" htmlFor="observacao">
                        <textarea autoComplete="off" className="form-control" id="observacao" value={observacao}
                        onChange={(e) => setObservacao(e.target.value)} rows={3}></textarea>
                        </FormGroup>
                    </div>
                                
                </div>      

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary"  onClick={() => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } } >
                Fechar
            </Button>
            <Button type="submit" variant="primary">
                Confirmar
            </Button>           
            </Modal.Footer>
            </form>
            </Modal>


            <Modal  size="lg" style={{zIndex}} aria-labelledby="contained-modal-title-vcenter" centered show={showModalRecusa} onHide={ () => { if (window.confirm('Você tem certeza que deseja sair sem salvar?')) handleClose() } }>
            <form onSubmit={handleSubmitRecusa}>
            <Modal.Header closeButton>
            <Modal.Title>Recusar Orçamento</Modal.Title>
            </Modal.Header>           
            <Modal.Body>               
                <div className="card">                  
                    <div className="card-header" style={{background:"#e6e6ff"}}>
                        <strong>Dados Pré-agendamento</strong>
                    </div>
                    <hr />   
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputPaciente" label="Paciente" htmlFor="paciente">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="paciente"
                                name="nm_paciente" value={nm_paciente}  />                                
                        </FormGroup>
                    </div>      
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputProcedimento" label="Procedimento" htmlFor="procedimento">
                             <input type="text" autoComplete="off"  disabled className="form-control" id="ds_procedimento"
                                name="ds_procedimento" value={ds_procedimento}  />                                
                        </FormGroup>
                    </div>     
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacaoComercial" label="Observacao Comercial" htmlFor="observacaoComercial">
                        <textarea autoComplete="off" disabled className="form-control" id="observacaoComercial" value={observacaoComercial}
                         rows={3}></textarea>
                        </FormGroup>
                    </div>                                       
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacao" label="Observacao" htmlFor="observacao">
                        <textarea autoComplete="off" required className="form-control" id="observacao" value={observacao}
                        onChange={(e) => setObservacao(e.target.value)} rows={3}></textarea>
                        </FormGroup>
                    </div>
                                
                </div>      

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary"  onClick={() => { if (window.confirm('Você tem certeza que deseja sair salvar?')) handleClose() } } >
                Fechar
            </Button>
            <Button type="submit" variant="primary">
                Confirmar
            </Button>           
            </Modal.Footer>
            </form>
            </Modal>


        </div>

    );


}
export default Orcamentos;
