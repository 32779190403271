export const strings = {
    documentos : "documentos",
    listaMedicos : "listaMedicos",
    listaEspecialidades : "listaEspecialidades",
    listaProcedimentos : "listaProcedimentos",
    orcamentos : "orcamentos",
    token: "token",
    role: "role",
    user: "user",
    listaOrcamentoStatus: "listaOrcamentoStatus",
    viewBack: "viewBack",
    activeRoute: "activeRoute"
}

//letter of A - Z input keyboard
export const letters = [65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90];
export const numbers = [48,49,50,51,52,53,54,55,56,57,96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
export const space = 32;      
 
export const anestesias = {
    geral : "Geral",
    raqui : "Raqui",
    peridural : "Peridural",
    local : "Local",
    sedacao : "Sedação"
}

export const acomodacoes = {
    privativa : "Privativa",
    semiprivativa : "Semi-privativa",
    ambulatorial : "Ambulatorial"
}

export const diarias = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    99: "Mais"
}

export const diariasUti = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    99: "Mais"
}

export const quantidadesSangue = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    99: "Mais"
}

export const temposSala = {
    1 : "1",
    2 : "2",
    3 : "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    99: "Mais"
}


export const temposSalaMin = {
    0  : "00",
    30 : "30"
}

export const simNao = {
   sim: "Sim",
   nao: "Não"
}