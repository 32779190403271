
function RadioButton(props : any){
    
    return(       
        <div className="icheck-primary d-inline" onClick={props.disabled ? false : props.onClick}>
            <input disabled={props.disabled} type="radio" name={props.name} value={props.value} checked={props.checked}/>
            <label style={{paddingLeft: "5px", paddingRight: "15px"}} >
                {props.label} 
            </label>
        </div>
    );
}

export default RadioButton;