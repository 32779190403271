import { useEffect, useState } from "react";
import { OrcamentosInterface } from "../../interfaces/orcamentoInterface";
import { customStyles } from "../../utils/datatableTheme";
import DataTable from 'react-data-table-component';
import { format } from "date-fns";

export default function OrcamentosTables(props: Props){


    const [omitColumn, setOmitColumn] = useState(false);

    useEffect(() => {
        if(window.innerWidth < 769){
          setOmitColumn(true);
        }
      }, []); 
     
    const array =  Object.values(props.orcamentos);
    
    const rows = array.map( orcamento => {

        let formattedDate;
        if(orcamento.dt_agenda){
          const date = new Date(orcamento.dt_agenda);
          formattedDate = format(date, "dd/MM/yyyy HH:mm");
        }
        const editar = orcamento.orcamentoStatus.alterar;
        const cancelar = orcamento.orcamentoStatus.cancelar ? false : true;

        let status;
        if(orcamento.status_id === 'C'){
          if(orcamento.proced_id){
            status = 'PA';
          }else{
            status = 'AO'
          }
        }else{
          status = 'C';
        }

        //<span style={{padding: "5px"}}><button disabled={editar} onClick={ () => props.editar(orcamento.id)} ><i className="fa fa-edit" title="editar" aria-hidden="true"></i></button></span>

        return( 
            {
              id: orcamento.id,
              nmPaciente : orcamento.nm_paciente,
              dtAgenda:  formattedDate,
              dsProcedimento : orcamento.ds_procedimento,                
              status : orcamento.orcamentoStatus ? orcamento.orcamentoStatus.nome : '',
              nr_seq_proc_interno : <div>{orcamento.nr_seq_proc_interno ? orcamento.nr_seq_proc_interno : ''}{'  '}{orcamento.proced_id ? <i style={{color:'blue'}} className='fa fa-bookmark' title='Proced. Gerenciado' ></i> : ''}</div>,
              viewDoc: 
                  <div>
                    <span style={{padding: "5px"}}>{ orcamento.status_id === 'OE' ?  <button onClick={ () => props.confirmar(orcamento.id, orcamento.observacao, orcamento.nm_paciente, orcamento.ds_procedimento, orcamento.observacaoComercial)}><i className="fa fa-check" title='confirmar' aria-hidden="true"></i></button> : ''}</span>
                    <span style={{padding: "5px"}}>{ orcamento.status_id === 'OE' ?  <button onClick={ () => props.recusar(orcamento.id, orcamento.observacao, orcamento.nm_paciente, orcamento.ds_procedimento, orcamento.observacaoComercial)}><i className="fa fa-ban" title='recusar' aria-hidden="true"></i></button> : ''}</span>
                    <span style={{padding: "5px"}}><button onClick={ () => props.visualizar(orcamento.id)}><i className={editar ? "fa fa-edit" : "fa fa-search"} title={editar ? 'editar' : 'visualizar'} aria-hidden="true"></i></button></span>
                    <span style={{padding: "5px"}}><button disabled={!orcamento.arquivo} onClick={ () => props.getFile(orcamento.descricao, orcamento.id, orcamento.arquivo, orcamento.proced_id ? 'pacote' : 'orcamento')} ><i className="fa fa-file-pdf" title="visualizar orçamento" aria-hidden="true"></i></button></span>
                    <span style={{padding: "5px"}}><button disabled={cancelar} onClick={ () => props.inativar(orcamento.id, status, `${orcamento.nm_paciente} - ${orcamento.ds_procedimento}`)} ><i className={orcamento.status_id !== 'C' ? "fa fa-times-circle" : "fa fa-recycle"} title={orcamento.status_id !== 'C' ? "cancelar" : "voltar para pendente"} aria-hidden="true"></i></button></span>
                  </div> 

            }
        )
    });


    const columns = [
        {
          name: 'Paciente',
          selector: 'nmPaciente',
          sortable: true,
          responsive: true,     
          width: "18%",
          style:{ fontWeight: 1000}
        },
        {
          name: 'Proc. Interno',
          selector: 'nr_seq_proc_interno',
          sortable: true,
          responsive: true, 
          maxWidth: "130px"
        }, 
        {
          name: 'Procedimento',
          selector: 'dsProcedimento',
          sortable: true,
          responsive: true, 
          width: "25%"
        },   
        {
          name: 'Data Prevista',
          selector: 'dtAgenda',
          sortable: false,
          right: true,
          responsive: true   ,
          omit: omitColumn ,
          maxWidth: "130px"
            
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          right: true,
          responsive: true,
          style:{ fontWeight: 500}
      
        },
        {
            name: '',
            selector: 'viewDoc',
            sortable: false,
            right: true,
            responsive: true,
            minWidth: '200px'             
          },
  
  
      ];



    return (
        <div className="form-horizontal">
            <div className="card-body">
            <DataTable
                customStyles={customStyles}
                data={rows}
                columns={columns}
                pagination={true}
                className="table table-bordered"
                responsive={true}
                paginationComponentOptions= { {rowsPerPageText : 'Resultados por página'}}
            />
            </div>
        </div>
    )
}


interface Props{
    orcamentos: OrcamentosInterface;
    getFile: (name: string, id:string, fileName:string, tipo: string) => void;
    editar: (id:string) => void;
    inativar: (id:string, status:string, name:string) => void;
    visualizar: (id:string) => void;
    confirmar: (id:string, observacao:string, nmPaciente:string, dsProcedimento:string, observacaoComercial: string) => void;
    recusar: (id:string, observacao:string, nmPaciente:string, dsProcedimento:string, observacaoComercial: string) => void;
}