function FormGroup(props : Props){

    return (
        <div className={props.className}>
            <label htmlFor={props.htmlFor}>{props.label}</label>
            {props.children}
        </div>
    )

}

export default FormGroup;


interface Props{
    className: string
    htmlFor: string, 
    label: string,
    id: string, 
    children: any
}
