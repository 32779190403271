import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './history';

import { AuthProvider } from './context/AuthContext';

import Header from './components/Header';
import Menu from './components/Menu';
import Footer from './components/Footer';



import 'toastr/build/toastr.min.js';
import 'toastr/build/toastr.css';
import { ModalComponent } from './components/Modal';
import { ModalProvider } from './context/ModalContext';
import { LoaderProvider } from './context/LoaderContext';
import LoaderSpinner from './components/LoaderSpinner';
import { RouterContext, RouterProvider } from './context/RouterContext';

require('dotenv');

function App() {
    return (
      <AuthProvider>
        <RouterProvider>
          <Header/>
          <Menu/>     
          <ModalProvider>
            <ModalComponent />    
              <LoaderProvider >   
                <LoaderSpinner />
                <Router history={history}>      
                  <Routes />
                </Router>     
              </LoaderProvider>     
          </ModalProvider>
          <Footer />
        </RouterProvider>
      </AuthProvider>
    );
}

export default App;
