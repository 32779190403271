import { useState, useEffect, useContext } from "react";
import FormGroup from "../../components/FormGroup";
import ComercialService from "../../services/comercial/comercialService";
import * as messages from '../../components/Toastr';
import history from "../../history";
import * as session from '../../utils/sessionStorage';
import { strings, letters, numbers } from '../../utils/strings';
import Select from 'react-select'
import { LoaderContext } from "../../context/LoaderContext";
import { getEspecialidades, getMedicos, getProcedimentos } from "../../utils/getSelects";


function DocumentoForm({ ...props }) {


    const params = props.match.params;
    const comercialService = new ComercialService();

    //const [nr_seq_pacote, setNrSeqPacote] = useState<Number | undefined | any>();
    const [pacotes, setPacotes] = useState<String | undefined | any>();
    const [nr_seq_proc_interno, setProcInterno] = useState<Number | undefined | any>();
    const [vigencia, setVigencia] = useState<Date | undefined | any>();
    const [cd_especialidade, setCdEspecialidade] = useState<String | undefined | any>();
    const [uploadFile, setUploadFile] = useState<File | undefined | any>();
    const [codigo, setCodigo] = useState<Number | undefined | any>();
    const [procedimento, setProcedimento] = useState<String | undefined | any>();
    const [usuarios, setUsuarios] = useState<Array<String> | undefined | any>();
    const [descricao, setDescricao] = useState<String | undefined | any>();
    const [fileName, setFileName] = useState<String>();
    const [especialidades, setEspecialidades] = useState([])
    const [medicos, setMedicos] = useState([]);
    const [procedimentos, setProcedimentos] = useState([]);
    const [descProced, setDescProced] = useState('');
    const [origemProced, setOrigemProced] = useState<Number | undefined | any>();
    const [searching, setSearching] = useState(false);

    const { setShow } = useContext(LoaderContext);



    useEffect(() => {
        getRegistros();
    }, []);

    useEffect(() => {
        getPacotes();
    }, [codigo, origemProced]);

    async function getRegistros() {

        setShow(true);

        if (!especialidades || especialidades.length < 1) {
            const listaEspecialidades = await getEspecialidades();
            setEspecialidades(listaEspecialidades);
        }


        if (!medicos || medicos.length < 1) {
            const listMedicos = await getMedicos();
            setMedicos(listMedicos);
        }

        if (params.id) {

            try {

                const response = await comercialService.getById(params.id);

                //setNrSeqPacote(response.data.nr_seq_pacote);
                setVigencia(response.data.vigencia ? response.data.vigencia.substr(0, 10) : null);
                setDescricao(response.data.descricao);
                setCodigo(response.data.codigo);
                setOrigemProced(response.data.origem_proced);
                setProcInterno(response.data.nr_seq_proc_interno);
                setProcedimento(response.data.procedimento);
                setFileName(`${response.data.descricao}.pdf`);

                getPacotes();

                if (response.data.usuarios) {

                    const array: any = Object.values(response.data.usuarios);
                    const rows = array.map(row => {
                        return (
                            {
                                value: row.nm_usuario,
                                label: row.nm_usuario
                            }
                        )

                    });

                    setUsuarios(rows);
                }

                if (response.data.cd_especialidade) {

                    const row = {
                        value: response.data.cd_especialidade,
                        label: response.data.especialidade.ds_especialidade
                    }
                    setCdEspecialidade(row);
                }


                /*if (response.data.nr_seq_pacote) {

                    const row = {
                        value: response.data.nr_seq_pacote,
                        label: response.data.pacote.ds_observacao
                    }
                    setNrSeqPacote(row);
                }*/

                if (response.data.codigo) {
                    const row = {
                        value: response.data.codigo,
                        label: `${response.data.codigo} - ${response.data.procedimento.ie_origem_proced} - ${response.data.procedimento.ds_procedimento}`
                    }
                    setProcedimento(row);

                }

            } catch (error) {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao recuperar o registro para edição");
                history.push("/documentos");
            }

        }

        setShow(false);
    }

    async function getPacotes() {

        if (codigo && origemProced) {

            setShow(true); 
            const response = await comercialService.getPacotes({ cd_proced_pacote: codigo, ie_origem_proced: origemProced });
            
            let pacotesAux = ''; 
            for(var i=0; i < response.data.length; i++){
                pacotesAux = `${pacotesAux !== '' ? `${pacotesAux} | ` : ''}${response.data[i].cd_estabelecimento}.${response.data[i].nr_seq_pacote}`;
            }
           
            setPacotes(pacotesAux);            
            setShow(false);
        }

    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();

        if (!fileName) {
            messages.mensagemErro("O Arquivo é obrigatório.")
            return null;
        }

        var formData = new FormData();

        if (vigencia) {
            formData.append('vigencia', vigencia);
        }
        if (uploadFile) {
            formData.append('arquivo', uploadFile);
        }
        if (cd_especialidade) {
            formData.append('cd_especialidade', cd_especialidade.value);
        }

        /*if (nr_seq_pacote) {
            formData.append('nr_seq_pacote', nr_seq_pacote.value);
        }*/

        if (codigo) {
            formData.append('codigo', codigo);
        } else {
            messages.mensagemErro("O Procedimento é obrigatorio");
            return null;
        }

        if (descricao) {
            formData.append('descricao', descricao);
        }

        if (nr_seq_proc_interno) {
            formData.append('nr_seq_proc_interno', nr_seq_proc_interno);
        }

        if (origemProced) {
            formData.append('origem_proced', origemProced);
        }

        if (usuarios) {

            const rows = usuarios.map(row => {
                return (
                    {
                        nm_usuario: row.value,
                    }
                )
            });

            formData.append('usuarios', JSON.stringify(rows));

        }

        if (params.id) {

            comercialService.put(params.id, formData)
                .then(response => {
                    const sessionDoc = { descricao: response.data.descricao };
                    session.setSession(strings.documentos, JSON.stringify(sessionDoc));
                    messages.mensagemSucesso("Registro alterado com sucesso");
                    history.push('/documentos');
                }).catch(error => {
                    messages.mensagemErro("Ocorreu um erro ao alterar o registro.");
                    console.log(error);
                });

        } else {

            comercialService.post(formData)
                .then(response => {
                    const sessionDoc = { descricao: response.data.descricao };
                    session.setSession(strings.documentos, JSON.stringify(sessionDoc));
                    messages.mensagemSucesso("Registro incluído com sucesso");
                    history.push('/documentos');
                }).catch(error => {
                    messages.mensagemErro("Ocorreu um erro ao inserir o registro.");
                    console.log(error.response);
                });
        }


    }


    const handleUploadClick = event => {
        var fileAux = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(fileAux)

        let name = fileAux.name;
        name = name.replace(/[ÀÁÂÃÄÅ]/g,"A");
        name = name.replace(/[àáâãäå]/g,"a");
        name = name.replace(/[ÈÉÊË]/g,"E");
        name = name.replaceAll(" ","_",);
        setUploadFile(fileAux);
        setFileName(name);
    };

    function voltar() {
        history.push("/documentos");
    }

    async function selectProcedClicked(e) {

        if (letters.includes(e.keyCode) || numbers.includes(e.keyCode) ) { 

            if (descProced.length > 2 && !searching) {
                try {
                    setSearching(true);
                    const search = descProced.replaceAll(" ", "+");
                    const rows = await getProcedimentos(search, [4]);
                    setProcedimentos(rows);
                    setSearching(false);
                } catch (error) {
                    console.log(error);
                }
                setDescProced('');
            }

        }
        
    }


    function setProcedimentoSelected(e) {

        setProcedimento(e);
        if (e !== null) {
            setCodigo(e.value);
            const proc =  e.label.split('-');          
            setOrigemProced(proc[1].trim());
            setProcInterno(proc[2].trim());         

        }
        setDescProced('');
    }


    return (

        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Dados do Documento</h3>
                        </div>
                        {/* /.card-header */}
                        {/* form start */}
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <FormGroup className="form-group col-22 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        id="inputDescricao" label="Descrição*" htmlFor="descricao">
                                        <input type="text" className="form-control" id="descricao" required placeholder="digite a descrição"
                                            name="descricao" maxLength={100} value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="row" style={{ zIndex: 10, position: 'relative' }} >
                                    <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        id="inputProcedimento" label="Procedimento" htmlFor="procedimento">

                                        <Select placeholder="Digite o procedimento e tecle Enter para pesquisar..." name="procedimento"
                                            options={procedimentos} value={procedimento}
                                            onInputChange={(e) => setDescProced(e)}
                                            onKeyDown={(e) => selectProcedClicked(e)}
                                            onChange={(e) => setProcedimentoSelected(e)}
                                            isClearable={true}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="row" style={{ zIndex: 9, position: 'relative' }} >
                                    <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                        id="inputVigencia" label="Vigência Final" htmlFor="vigencia">
                                        <input type="date" className="form-control" id="vigencia" placeholder="digite a vigência do documento"
                                            name="vigencia" value={vigencia} onChange={(e) => setVigencia(e.target.value)} />
                                    </FormGroup>

                                    <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                        id="inputEspecialidade" label="Especialidade" htmlFor="especialidade">
                                        <Select isClearable={true} placeholder="Seleciona a especialidade..." name="cd_especialidade" options={especialidades} value={cd_especialidade} onChange={(e) => setCdEspecialidade(e)} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                       id="inputProcInterno" label="Proc Interno*" htmlFor="procInterno">
                                       <input type="text" className="form-control" id="nr_seq_proc_interno" required
                                           name="nr_seq_proc_interno" maxLength={100} value={nr_seq_proc_interno} onChange={(e) => setProcInterno(e.target.value)}
                                        />
                                    </FormGroup>                                
                                    <FormGroup className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                                        id="inputPacote" label="Pacotes" htmlFor="pacote">
                                        <input type="text" className="form-control" id="pacote" required placeholder="Pacotes"
                                            name="pacote" disabled maxLength={100} value={pacotes} />
                                    </FormGroup>
                                </div>

                                <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    id="inputArquivo" label="Arquivo*" htmlFor="arquivo">
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input type="file"   accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
                                                    id="inputArquivoFile" onChange={handleUploadClick} />
                                            <label className="custom-file-label" htmlFor="inputArquivoFile">{fileName ? fileName : 'Selecione o arquivo PDF'}</label>
                                        </div>
                                        <div className="input-group-append">
                                        </div>
                                    </div>
                                </FormGroup>

                                <div className="row">
                                    <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        id="inputUsuarios" label="Médicos" htmlFor="usuarios">
                                        <Select isMulti={true} placeholder="Seleciona os médicos..." name="usuarios" options={medicos} value={usuarios} onChange={(e) => setUsuarios(e)} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="card-footer float-right">
                                <div className="row">
                                    <div style={{ paddingRight: 10 }}>
                                        <button className="btn btn-danger" onClick={voltar}>Fechar</button>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Salvar</button>
                                </div>
                            </div>
                        </form>
                    </div>  {/*card-body*/}
                </div>
            </section>
        </div>
    );


}


export default DocumentoForm;