import { useState, useEffect, useContext } from "react";
import PacotesTable from './pacotesTable';
import { LoaderContext } from "../../context/LoaderContext";
import ComercialService from "../../services/comercial/comercialService";
import * as messages from '../../components/Toastr';
import { Button, Modal } from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import { Estabelecimentos } from "../../utils/estabelecimentos";
import Select from 'react-select';
import { cpfMask } from "../../utils/cpfMask";
import { getStorage } from "../../utils/sessionStorage";
import { strings } from "../../utils/strings";
import history from "../../history";
import comercialGetFile from "../../utils/comercialGetFile";
import * as session from '../../utils/sessionStorage';


function Pacotes(){


    let callApi = false;

    const [rows, setRows] = useState([]);

    const comercialService = new ComercialService();

    const { setShow } = useContext(LoaderContext);
    const [showModal, setShowModal] = useState(false);
    const [nome, setNome] = useState('');
    const [procInterno, setProcInterno] = useState('');
    const [idProced, setIdProced] = useState('');
    const [estabelecimento, setEstabelecimento] = useState<any>();
    const [dtNascimento, setDtNascimento] = useState<string>('');
    const [dtAgenda, setDtAgenda] = useState<string>('');
    const [hrAgenda, setHrAgenda] = useState<string>('');
    const [cpf, setCpf] = useState<string>();
    const [equipe, setEquipe] = useState<string>();
    const [equipamento, setEquipamento] = useState<string>();
    const [observacao, setObservacao] = useState<string>();
    const [valEstab, setValEstab] = useState<string>();
    const [valNome, setValNome] = useState<string>();
    const [valNasc, setValNasc] = useState<string>();
    const [valDtAgenda, setValDtAgenda] = useState<string>();
    const [valHrAgenda, setValHrAgenda] = useState<string>();
    const [arquivo, setArquivo] = useState<string>();
    const [zIndex, setZIndex] = useState(10000);

    const estabelecimentos = Estabelecimentos;

    useEffect(() => {
        getProceds();
    }, []);

    function getProceds() {

        if(!callApi){
            setShow(true);   
            callApi = true;

            comercialService.getProcedsUsuario()
                .then(response => {
                    setRows(response.data.procedGerenciados);
                })
                .catch(error => {
                    console.log(error.response);
                })
                .finally(() =>
                    setShow(false)
                );
        }

    }

    async function getFile(name: string, id: string, fileName: string, tipo: string) {

        setShow(true);
        await comercialGetFile(name, id, fileName, tipo);
        setShow(false);
        
    }

    function handleClose(){
        setShowModal(false);
    }

    function handleModal(id, name, nrSeqProc, arquivo){
        setShow(false);
        setIdProced(id);
        setProcInterno(`${nrSeqProc} - ${name}`);
        setArquivo(arquivo);
        setNome('');
        setDtNascimento('');
        setCpf('');
        setDtAgenda('');
        setHrAgenda('');
        setEstabelecimento('');
        setEquipamento('');
        setEquipe('');
        setObservacao('');
        setShowModal(true);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

        setShow(true);   
        setZIndex(100);

        e.preventDefault();

        if(!estabelecimento){
            if(!valEstab){
                setValEstab('Informe a unidade');
            }
            finallyModal()
            return false;
        }

        const dataAtual = new Date();
        const dataAgenda = new Date(dtAgenda)
        dataAgenda.setHours(dataAgenda.getHours() + 24);
        dataAtual.setHours(0,0,0,0);

        if(dataAgenda < dataAtual){
            if(!valDtAgenda){
                setValDtAgenda('Data de agendamento não pode ser menor que a data atual.');
            }
            finallyModal()
            return false;
        }

        const user = JSON.parse(getStorage(strings.user));        

        const dt = `${dtAgenda} ${hrAgenda}:00`;

        const formData = {
            proced_id : idProced,
            nm_usuario : user,
            nm_paciente : nome,
            nr_cpf : cpf ? cpf.replace(/([^\d])+/gim, '') : undefined,
            dt_nascimento : dtNascimento,
            observacao,
            dt_agenda : dt,
            ds_equipe_anest : equipe,
            ds_equipamento : equipamento,
            status_id : 'PA', //pendente agendamento table: orcamento_status
            fileName: arquivo,
            cd_estabelecimento: estabelecimento ? estabelecimento.value : undefined
        }

        comercialService.postOrcamento(formData)
        .then(response => {
            const sessionDoc = { nomePacienteSearch: response.data.nm_paciente };
            session.setSession(strings.orcamentos, JSON.stringify(sessionDoc));
            messages.mensagemSucesso("Registro incluído com sucesso");
            handleClose();
            history.push('/orcamentos');
        }).catch(error => {
            messages.mensagemErro("Ocorreu um erro ao inserir o registro.");
            console.log(error.response);
        }).finally(() =>
           finallyModal()
         );
    }

    function finallyModal(){
        setShow(false);
        setZIndex(10000);
    }

    function validarFields(field){
        if(field === 'estab'){
            if(!estabelecimento){
                setValEstab('Informe a unidade.');
            }
        }else if(field === 'nome'){
            if(!nome){
                setValNome('Informe o nome do paciente.');
            }
        } else if(field === 'nasc'){
            if(!dtNascimento){
                setValNasc('Informe data de nascimento do paciente.');
            }
        } else if(field === 'dtAgenda'){
            if(!dtAgenda){
                setValDtAgenda('Informe a data de agendamento.');
            }
        } else if(field === 'hrAgenda'){
            if(!hrAgenda){
                setValHrAgenda('Informe a hora de agendamento.');
            }
        }
    }
    
    function setSelect(e, type){

        if(type === 'estab'){
            setEstabelecimento(e);
            if(valEstab){
                setValEstab(undefined);
            }
        }else if(type === 'nome'){
            setNome(e.target.value);
            if(valNome){
                setValNome(undefined);
            }
        }else if(type === 'nasc'){
            setDtNascimento(e.target.value);
            if(valNasc){
                setValNasc(undefined);
            }
        }else if(type === 'dtAgenda'){
            setDtAgenda(e.target.value);
            if(valDtAgenda){
                setValDtAgenda(undefined);
            }
        }else if(type === 'hrAgenda'){
            setHrAgenda(e.target.value);
            if(valHrAgenda){
                setValHrAgenda(undefined);
            }
        }
       
    }

    return (
        <>
            <div className="col-12">
            <div className="callout callout-info">
            <h5>Olá!</h5>
            Utilize a listagem abaixo para navegar pelos procedimentos gerenciados.<br/>
            Caso não encontre o procedimento ou necessite um orçamento personalizado, 
            utilize o botão <strong>"Solicitar Orçamento".</strong>
            </div>

            <div className="invoice p-3 mb-3">
            <div className="row">
                <div className="col-12">
                <h4>
                    Lista de Procedimentos                     
                    <a href="/orcamentos/adicionar"  type="button" className="btn btn-success float-right" style={{margin: "20px"}}> <i className="fa fa-plus"></i> Solicitar Orçamento</a>
                </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12 table-responsive">
                    <PacotesTable documentos={rows} getFile={getFile} handleModal={handleModal} />
                </div>
            </div>
            </div>
        </div> 

        <Modal  size="lg" style={{zIndex}} aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={ () => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } }>
            <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
            <Modal.Title>Solicitar Agendamento</Modal.Title>
            </Modal.Header>           
            <Modal.Body>               
                <div className="card">
                    <div className="card-header" style={{background:"#e6e6ff"}}>
                        <strong>Dados do Paciente</strong>
                    </div>
                    <hr />
                    <div className="row">
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputNome" label="Nome do Paciente*" htmlFor="nome">
                        <input type="text" className="form-control" id="nome" required placeholder="digite o nome"
                            name="nome" autoComplete="off" maxLength={100} value={nome} onBlur={() => validarFields('nome')} onChange={(e) => setSelect(e, 'nome')} />
                            <span style={{color: "red"}}>{valNome}</span>
                        </FormGroup>
                    </div>
                    <div className="row">
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputDtNascimento" label="Data de Nascimento*" htmlFor="dtNascimento">
                            <input type="date" className="form-control" id="dtNascimento" required 
                                name="dtNascimento" autoComplete="off" value={dtNascimento} onBlur={() => validarFields('nasc')} onChange={(e) => setSelect(e, 'nasc')}  />
                                <span style={{color: "red"}}>{valNasc}</span>
                        </FormGroup>  
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputCpf" label="CPF" htmlFor="cpf">
                            <input type="text" autoComplete="off" className="form-control" maxLength={14} id="cpf" placeholder="digite o cpf do paciente"
                                name="cpf" value={cpf} onChange={(e) => setCpf( cpfMask(e.target.value) )} />
                        </FormGroup>  
                    </div>
                    <hr />                    
                    <div className="card-header" style={{background:"#e6e6ff"}}>
                        <strong>Dados da Cirurgia</strong>
                    </div>
                    <hr />
                    <div className="row">
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputNome" label="Procedimento" htmlFor="nome">
                        <input type="text" autoComplete="off" className="form-control" id="procInterno" disabled
                            name="procedimento" value={procInterno} />
                        </FormGroup> 
                    </div>                  
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            id="inputEstab" label="Selecione o Hospital*" htmlFor="estabelecimento">
                            <Select isClearable={true}
                            placeholder="Seleciona a unidade..." name="cd_estabelecimento" 
                            options={estabelecimentos} value={estabelecimento} onBlur={() => validarFields('estab')} onChange={(e) => setSelect(e, 'estab')} />
                             <span style={{color: "red"}}>{valEstab}</span>
                        </FormGroup>
                    </div>
                    <div className="row">
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputDtAgenda" label="Data Prevista de Agendamento*" htmlFor="inputDtAgenda">
                            <input type="date" autoComplete="off" className="form-control" id="dtAgenda"
                                name="dtAgenda" required value={dtAgenda} onBlur={() => validarFields('dtAgenda')} onChange={(e) => setSelect(e, 'dtAgenda')}  />
                            <span style={{color: "red"}}>{valDtAgenda}</span>
                        </FormGroup>    
                        <FormGroup className="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                            id="inputHrAgenda" label="Hora de Agendamento*" htmlFor="inputHrAgenda">
                            <input type="time" autoComplete="off" className="form-control" id="dtAgenda"
                                name="hrAgenda" required value={hrAgenda} onBlur={() => validarFields('hrAgenda')} onChange={(e) => setSelect(e, 'hrAgenda')}  />
                                <span style={{color: "red"}}>{valHrAgenda}</span>
                        </FormGroup>    
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputEquipe" label="Equipe Anestésica" htmlFor="equipe">
                        <input type="text" autoComplete="off" className="form-control" id="equipe"  placeholder="digite o nome da equipe"
                            name="equipe" maxLength={100} value={equipe} onChange={(e) => setEquipe(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputEquipamantos"  label="Equipamentos" htmlFor="equipamentos">
                        <input type="text" autoComplete="off" className="form-control" id="equipamentos"  placeholder="digite os equipamentos"
                            name="equipamentos" maxLength={1000} value={equipamento} onChange={(e) => setEquipamento(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="row"> 
                        <FormGroup className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        id="inputObservacao" label="Observacao" htmlFor="observacao">
                        <textarea autoComplete="off" className="form-control" id="observacao" value={observacao}
                         onChange={(e) => setObservacao(e.target.value)} rows={3}></textarea>
                        </FormGroup>
                    </div>
                                  
                </div>      
            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary"  onClick={() => { if (window.confirm('Você tem certeza que deseja sair sem confirmar o agendamento?')) handleClose() } } >
                Fechar
            </Button>
            <Button type="submit" variant="primary">
                Confirmar
            </Button>           
            </Modal.Footer>
            </form>
        </Modal>

        </>
    );
}

export default Pacotes;