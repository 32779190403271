import { createContext, ReactNode, useState } from "react";
import * as session from '../utils/sessionStorage';
import { strings } from "../utils/strings";

interface RouterContextData{
    activeRoute : string;
    setRoute: (route:string) => void;    
}

interface RouterProviderProps{
    children: ReactNode;
}

export const RouterContext = createContext( { } as RouterContextData);

export function RouterProvider( {children} : RouterProviderProps){

    const [activeRoute, setActiveRoute] = useState(getRoute());

    function getRoute(){
        const route = JSON.parse(session.getSession(strings.activeRoute));
        return route ? route : '/';
    }

    function setRoute(route:string){
        session.setSession(strings.activeRoute, JSON.stringify(route));
        setActiveRoute(route);
    }
   
    return (
        <RouterContext.Provider value={ {activeRoute, setRoute} }>
            {children}
        </RouterContext.Provider>
    )
}