import React, {useContext } from 'react'
import { Context } from '../context/AuthContext';

export default function Login() {

    const { handleLogin, setUserInput, setPasswordInput} = useContext(Context);

    function handleLocalLogin(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        handleLogin();
    }

    return (
        <div>
            <body className="login-page">
                <div className="login-box">
                <div className="login-logo">
                    <div><b>HSVP</b> Portal</div>
                </div>
                {/* /.login-logo */}
                <form onSubmit={handleLocalLogin}>
                    <div className="card">
                        <div className="card-body login-card-body">
                        <p className="login-box-msg">Efetue login para iniciar</p>   
                            <div className="input-group mb-3">
                            <input type="user" required className="form-control" placeholder="Usuário"  onChange={(e) => setUserInput(e.target.value)}/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                <span className="fas fa-user" />
                                </div>
                            </div>
                            </div>
                            <div className="input-group mb-3">
                            <input type="password" required className="form-control" placeholder="Senha" onChange={(e) => setPasswordInput(e.target.value)} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                <span className="fas fa-lock" />
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-8">
                                <div className="icheck-primary">
                                <input type="checkbox" id="remember" />
                                <label htmlFor="remember">
                                    Lembrar-me?
                                </label>
                                </div>
                            </div>
                            {/* /.col */}
                            <div className="col-4">
                            <button type="submit" className="btn btn-primary">Salvar</button>
                            </div>
                            {/* /.col */}
                            </div>                        
                        </div>
                        {/* /.login-card-body */}
                    </div>
                    </form>
                </div>
               
                {/* /.login-box */}
            </body>
        </div>

    );
}