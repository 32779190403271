import axios from 'axios';


const COMERCIAL_API = process.env.REACT_APP_API_COMERCIAL;

const apiComercial = axios.create({
  baseURL: COMERCIAL_API,
});

apiComercial.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    apiComercial.defaults.headers.Authorization = undefined;
    window.location.href = '/login';
  }
  return Promise.reject(error.message);
})

export default apiComercial;