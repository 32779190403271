import { Filter } from "../../interfaces/documentInterface";
import { FilterOrcamento } from "../../interfaces/orcamentoInterface";
import { getOrcamentoStatusSession } from "../../utils/getSelects";
import apiComercial from "../apis/ApiComercial";


export default class ComercialService{

    get(filter: Filter){

        let params = '?';

        if(filter.codigo){
            params = `${params}codigo=${filter.codigo}&`;
        }
        if(filter.origemProced){
            params = `${params}origem_proced=${filter.origemProced}&`;
        }
        if(filter.descricao){
            params = `${params}&descricao=${filter.descricao}&`;
        }
        if(filter.cd_especialidade){
            params = `${params}&cd_especialidade=${filter.cd_especialidade}&`;
        }
        if(filter.usuario){
            params = `${params}&usuario=${filter.usuario}&`;
        }
        if(filter.nr_seq_proc_interno){
            params = `${params}&nr_seq_proc_interno=${filter.nr_seq_proc_interno}`;
        }

        return apiComercial.get(`/procedGerenciados${params}`);        
    };

    getOrcamentos(filter: FilterOrcamento){

        let params = '?';

        if(filter.cd_estabelecimento){
            params = `${params}cd_estabelecimento=${filter.cd_estabelecimento}`;
        }
        
        if(filter.nm_paciente){
            params = `${params}&nm_paciente=%${filter.nm_paciente}%`;
            const response = getOrcamentoStatusSession();                         
                for(var i=0; i < response.length; i++){
                    params = `${params}&status=${response[i].value}`;
                }   
        }else{
            if(filter.status && filter.status.length > 0){
                for(var i=0; i < filter.status.length; i++){
                    params = `${params}&status=${filter.status[i]}`;
                }           
            }
        }

        if(filter.dt_agenda){
            params = `${params}&dt_agenda=${filter.dt_agenda}`;
        }

        if(filter.nr_seq_proc_interno){
            params = `${params}&nr_seq_proc_interno=${filter.nr_seq_proc_interno}`;
        }

        return apiComercial.get(`/orcamentos/${params}`);        
    };


    getOrcamentosUsuario(filter: FilterOrcamento){

        let params = '?';

        if(filter.cd_estabelecimento){
            params = `${params}cd_estabelecimento=${filter.cd_estabelecimento}`;
        }
        
        if(filter.nm_paciente){
            params = `${params}&nm_paciente=%${filter.nm_paciente}%`;
            const response = getOrcamentoStatusSession();                         
                for(var i=0; i < response.length; i++){
                    params = `${params}&status=${response[i].value}`;
                }   
        }else{
            if(filter.status && filter.status.length > 0){
                for(var j=0; j < filter.status.length; j++){
                    params = `${params}&status=${filter.status[j]}`;
                }           
            }
        }

        if(filter.dt_agenda){
            params = `${params}&dt_agenda=${filter.dt_agenda}`;
        }

        if(filter.nr_seq_proc_interno){
            params = `${params}&nr_seq_proc_interno=${filter.nr_seq_proc_interno}`;
        }
        return apiComercial.get(`/orcamentos/usuario/nm_usuario${params}`);        
    };
    
    getById(id : string){

        return apiComercial.get(`/procedGerenciados/${id}`);        
    };

    getOrcamentoById(id : string){

        return apiComercial.get(`/orcamentos/${id}`);        
    };

    post(documento : Object){   

        return apiComercial.post("/procedGerenciados", documento, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    put(id:string, documento : Object){   

        return apiComercial.put(`/procedGerenciados/${id}`, documento, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    patch(id:string, documento : Object){   

        return apiComercial.patch(`/procedGerenciados/${id}`, documento);

    };

    getFiles(id:string, fileName:string, tipo:string){
        if(tipo === 'pacote'){
            return apiComercial.get(`/getFile/${id}/${fileName}`, 
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            });
        }else{
            return apiComercial.get(`/orcamentos/getFile/${id}/${fileName}`, 
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            });
        }
       
    };


    getEspecialidades(filter: any){

        let params = '?';

        if(filter.cd_especialidade){
            params = `${params}cd_especialidade=${filter.cd_especialidade}&`;
        }
        if(filter.ds_especialidade){
            params = `${params}ds_especialidade=${filter.ds_especialidade}`;
        }

        return apiComercial.get(`/especialidades${params}`);        
    };


    
    getPacotes(filter: any){

        let params = '?';

        if(filter.cd_proced_pacote){
            params = `${params}cd_proced_pacote=${filter.cd_proced_pacote}&`;
        }
        if(filter.ie_origem_proced){
            params = `${params}ie_origem_proced=${filter.ie_origem_proced}`;
        }

        return apiComercial.get(`/pacotes${params}`);        
    };

    getMedicos(filter: any){

        let params = '?';

        if(filter.cd_pessoa_fisica){
            params = `${params}cd_pessoa_fisica=${filter.cd_pessoa_fisica}`;
        }

        return apiComercial.get(`/medicos${params}`);        
    };


    getProcedimentos(filter: any){

        let params = '?tipo=J&';

        if(filter.cd_procedimento){
            params = `${params}cd_procedimento=${filter.cd_procedimento}&`;
        }
        if(filter.ds_procedimento){
            params = `${params}ds_procedimento=${filter.ds_procedimento}&`;
        }

        if(filter.tabela && filter.tabela.length > 0){           
                filter.tabela.forEach(element => {
                    params = `${params}tabela=${element}&`;
                });
        }else{
            params = `${params}tabela=4&tabela=5`;
        }
                 
        return apiComercial.get(`/procedimentos${params}`);        
    };

    getProcedsUsuario(){       
                   
        return apiComercial.get(`/procedGerenciados/usuario/username?status=true`);        
    };


    postOrcamento(documento : Object){   

        return apiComercial.post("/orcamentos", documento, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    putOrcamentoUsuario(id:string, documento : Object){  
     
        return apiComercial.put(`/orcamentos/usuario/user/${id}`, documento, {
            headers: {
                'Content-Type':'application/json'
            }
        });
    };

    putOrcamento(id:string, documento : Object){  
     
        return apiComercial.put(`/orcamentos/${id}`, documento, {
            headers: {
                'Content-Type':'application/json'
            }
        });
    };


    getOrcamentoStatus(){                  
        return apiComercial.get(`/orcamentoStatus`);        
    };

    getOrcamentoUsuaurioById(id : string){

        return apiComercial.get(`/orcamentos/usuario/user/${id}`);        
    };

    patchOrcamento(id:string, documento : Object){   

        return apiComercial.patch(`/orcamentos/${id}`, documento);

    };

    patchConfirmarOrcamento(id:string, documento : Object){   

        return apiComercial.patch(`/orcamentos/confirmar/${id}`, documento, {
            headers: {
                'Content-Type': 'multipart/form-data'
                }
            }
        );
       

    };


}



