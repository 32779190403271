import ComercialService from "../services/comercial/comercialService";
import { getSession, setSession } from "./sessionStorage";
import { strings } from "./strings";

export async function getProcedimentos(descProced:string, tabela:any){

    const comercialService = new ComercialService();

    const search = descProced.replaceAll(" ", "+");
    const response = await  comercialService.getProcedimentos({cd_procedimento : undefined, ds_procedimento: search, tabela});                

    return response.data;
}



export async function getMedicos(){               
        
    let array = getSession(strings.listaMedicos);
    let listMedicos : any = [];

    if(!array){

        try{

            const comercialService = new ComercialService();
            
            const response = await comercialService.getMedicos({cd_pessoa_fisica : undefined});

            const rows : any =  Object.values(response.data);
            listMedicos = rows.map( row => {
                    return( 
                        {
                            value : row.user.nm_usuario,
                            label : row.medicoEspecialidade
                        }
                    )
            }); 

            setSession(strings.listaMedicos, JSON.stringify(listMedicos));
        
        }catch(error){
            console.log(error);
        }
    
    }else{               
        listMedicos = JSON.parse(array);
    }

    return listMedicos;
  
}


export async function getEspecialidades(){

    let array = getSession(strings.listaEspecialidades);
    let listaEspecialidades = [];

        if(!array){

            try{    

                const comercialService = new ComercialService();
                const response = await  comercialService.getEspecialidades({cd_especialidade : undefined, ds_especialidade: undefined});

                const array : any =  Object.values(response.data);
                listaEspecialidades = array.map( row => {
                        return( 
                            {
                                value : row.cd_especialidade,
                                label : row.ds_especialidade
                            }
                        )
            
                });

                setSession(strings.listaEspecialidades, JSON.stringify(listaEspecialidades));
            }catch(error){
                console.log(error);
            }   


        }else{
            listaEspecialidades = JSON.parse(array);
        }

    return listaEspecialidades;
}


export async function getOrcamentoStatus(){

    let array = getSession(strings.listaOrcamentoStatus);
    let listaStatus = [];

        if(!array){

            try{    

                const comercialService = new ComercialService();
                const response = await  comercialService.getOrcamentoStatus();

                const array : any =  Object.values(response.data);
                listaStatus = array.map( row => {
                        return( 
                            {
                                value : row.id,
                                label : row.nome
                            }
                        )
            
                });

                setSession(strings.listaOrcamentoStatus, JSON.stringify(listaStatus));
            }catch(error){
                console.log(error);
            }   

        }else{
            listaStatus = JSON.parse(array);
        }

    return listaStatus;
}


export function getOrcamentoStatusSession(){
    let array = getSession(strings.listaOrcamentoStatus);
    let listaStatus = JSON.parse(array);
    return listaStatus;
}