import ComercialService from "../services/comercial/comercialService";
import * as messages from '../components/Toastr';


export default async function comercialGetFile(name: string, id: string, fileName: string, tipo: string) {

    const comercialService = new ComercialService();

    if (id && fileName) {

        comercialService.getFiles(id, fileName, tipo)
            .then((response) => {

                const file = new Blob(
                    [response.data], 
                    {type: response.headers["content-type"]});
                
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => {
                console.log(error);
                messages.mensagemErro("Ocorreu um erro ao buscar o documento.");
            });           

    } else {
        messages.mensagemAlerta("Não há documento a exibir.");
    }
}